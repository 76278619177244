import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function useNewsletter() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const SubmitNewsletter = async (payload, token) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/newsletter-subscriber/`,
        payload
      );

      Swal.fire({
        icon: "success",
        text: response?.data?.message || "Subscribed Successfully",
        customClass: {
          confirmButton: "custom-red-button",
        },
      });
    } catch (error) {
      console.log("Error:", error);
      Swal.fire({
        icon: "error",
        text:
          error?.response?.data?.error ||
          "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
        customClass: {
          confirmButton: "custom-red-button",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return { SubmitNewsletter, loading };
}

export default useNewsletter;
