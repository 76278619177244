import React, { useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import GlobalContext from "../../context/GlobalContext";

const DualBarGraph = ({ data, title, title2 }) => {
  const { dark } = useContext(GlobalContext);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey={title} fill="#36A2EB" barSize={30} />
        <Bar dataKey={title2} fill="#FF6384" barSize={30} />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default DualBarGraph;
