import React, { useState, useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import GlobalContext from "../../context/GlobalContext";

const BarGraph = ({ usageData, title }) => {
  const { dark } = useContext(GlobalContext);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={usageData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" stroke={dark ? "#fff" : "#000"} />
        <YAxis stroke={dark ? "#fff" : "#000"} />
        <Tooltip />
        <Legend />
        <Bar dataKey={title} fill="#8884d8" barSize={50} />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default BarGraph;
