import axios from "axios";
const useRefreshToken = () => {
  const refresh = sessionStorage.getItem("refresh_token");
  const url = process.env.REACT_APP_BASE_URL;

  const RefreshToken = async () => {
    const data = {
      refresh_token: refresh,
    };
    try {
      const response = await axios.post(`${url}/users/refresh-token/`, data);
      window.location.reload();
      console.log({ response });
      sessionStorage.setItem("token", response.access_token);
    } catch (error) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("refresh_token");
      window.location.href = "/";
    }
  };
  return { RefreshToken };
};
export default useRefreshToken;
