const data = [
  {
    name: "Jan 23",
    Schule: 33,
    Klassen: 22,
    Registrierte: 10,
  },
  {
    name: "Feb 23",
    Schule: 15,
    Klassen: 35,
    Registrierte: 30,
  },
  {
    name: "Mar 23",
    Schule: 21,
    Klassen: 30,
    Registrierte: 28,
  },
  {
    name: "Apr 23",
    Schule: 28,
    Klassen: 25,
    Registrierte: 23,
  },
  {
    name: "May 23",
    Schule: 20,
    Klassen: 55,
    Registrierte: 36,
  },
  {
    name: "Jun 23",
    Schule: 25,
    Klassen: 23,
    Registrierte: 44,
  },
  {
    name: "Jul 23",
    Schule: 30,
    Klassen: 20,
    Registrierte: 48,
  },
  {
    name: "Aug 23",
    Schule: 38,
    Klassen: 28,
    Registrierte: 15,
  },
  {
    name: "Sep 23",
    Schule: 40,
    Klassen: 25,
    Registrierte: 34,
  },
  {
    name: "Oct 23",
    Schule: 15,
    Klassen: 18,
    Registrierte: 21,
  },
  {
    name: "Nov 23",
    Schule: 32,
    Klassen: 36,
    Registrierte: 44,
  },
  {
    name: "Dec 23",
    Schule: 3,
    Klassen: 42,
    Registrierte: 25,
  },
];
const dataSupportTicket = [
  {
    name: "Jan 23",
    open: 33,
    resolved: 22,
    pending: 10,
  },
  {
    name: "Feb 23",
    open: 15,
    resolved: 35,
    pending: 30,
  },
  {
    name: "Mar 23",
    open: 21,
    resolved: 30,
    pending: 28,
  },
  {
    name: "Apr 23",
    open: 28,
    resolved: 25,
    pending: 23,
  },
  {
    name: "May 23",
    open: 20,
    resolved: 55,
    pending: 36,
  },
];
const dataEmails = [
  {
    name: "Jan",
    Emails: 500,
  },
  {
    name: "Feb",
    Emails: 400,
  },
  {
    name: "Mar",
    Emails: 200,
  },
  {
    name: "Apr",
    Emails: 400,
  },
  {
    name: "May",
    Emails: 500,
  },
  {
    name: "Jun",
    Emails: 450,
  },
  {
    name: "Jul",
    Emails: 430,
  },
  {
    name: "Aug",
    Emails: 500,
  },
  {
    name: "Sep",
    Emails: 440,
  },
  {
    name: "Oct",
    Emails: 450,
  },
  {
    name: "Nov",
    Emails: 410,
  },
  {
    name: "Dec",
    Emails: 450,
  },
];
// Static data for different time frames
const staticData = [
  { date: "2024-10-08", sent: 600 },
  { date: "2024-10-07", sent: 600 },
  { date: "2024-09-25", sent: 700 },
  { date: "2024-09-27", sent: 800 },
  { date: "2024-09-28", sent: 700 },
  { date: "2024-09-29", sent: 800 },
  { date: "2024-09-22", sent: 700 },
  { date: "2024-09-21", sent: 800 },
  { date: "2024-08-24", sent: 600 },
  { date: "2024-05-25", sent: 700 },
  { date: "2024-03-26", sent: 800 },
];
const staticDataForRegisteredClasses = [
  { date: "2024-10-08", angemeldete_Klassen: 600 },
  { date: "2024-10-07", angemeldete_Klassen: 600 },
  { date: "2024-09-25", angemeldete_Klassen: 700 },
  { date: "2024-09-27", angemeldete_Klassen: 800 },
  { date: "2024-09-28", angemeldete_Klassen: 700 },
  { date: "2024-09-29", angemeldete_Klassen: 800 },
  { date: "2024-09-22", angemeldete_Klassen: 700 },
  { date: "2024-09-21", angemeldete_Klassen: 800 },
  { date: "2024-08-24", angemeldete_Klassen: 600 },
  { date: "2024-05-25", angemeldete_Klassen: 700 },
  { date: "2024-03-26", angemeldete_Klassen: 800 },
];
const incomeData = [
  { month: "January", monthlyIncome: 5000, totalIncome: 5000 },
  { month: "February", monthlyIncome: 7000, totalIncome: 12000 },
  { month: "March", monthlyIncome: 6000, totalIncome: 18000 },
  { month: "April", monthlyIncome: 8000, totalIncome: 26000 },
  { month: "May", monthlyIncome: 7500, totalIncome: 33500 },
  { month: "June", monthlyIncome: 9000, totalIncome: 42500 },
  { month: "July", monthlyIncome: 8500, totalIncome: 51000 },
  { month: "August", monthlyIncome: 9500, totalIncome: 60500 },
  { month: "September", monthlyIncome: 10000, totalIncome: 70500 },
  { month: "October", monthlyIncome: 11000, totalIncome: 81500 },
  { month: "November", monthlyIncome: 12000, totalIncome: 93500 },
  { month: "December", monthlyIncome: 13000, totalIncome: 106500 },
];
const staticDataSchool = [
  { date: "2024-10-07", neueSchulen: 600 },
  { date: "2024-10-08", neueSchulen: 600 },

  { date: "2024-09-25", neueSchulen: 700 },
  { date: "2024-09-27", neueSchulen: 800 },
  { date: "2024-09-28", neueSchulen: 700 },
  { date: "2024-09-29", neueSchulen: 800 },
  { date: "2024-09-22", neueSchulen: 700 },
  { date: "2024-09-21", neueSchulen: 800 },
  { date: "2024-08-24", neueSchulen: 600 },
  { date: "2024-05-25", neueSchulen: 700 },
  { date: "2024-03-26", neueSchulen: 800 },
];

const staticDataForSentEmail = [
  { date: "2024-10-08", sent: 600 },
  { date: "2024-10-02", sent: 700 },
  { date: "2024-10-07", sent: 800 },
  { date: "2024-10-28", sent: 700 },
  { date: "2024-10-29", sent: 800 },
  { date: "2024-10-22", sent: 700 },
  { date: "2024-10-21", sent: 800 },
  { date: "2024-10-24", sent: 600 },
  { date: "2024-10-25", sent: 700 },
  { date: "2024-10-26", sent: 800 },
];

const staticDataEmail = [
  // { date: "2024-09-30", nicht_verifiziert: 600 },
  { date: "2024-10-1", nicht_verifiziert: 600 },
  { date: "2024-10-2", nicht_verifiziert: 700 },
  { date: "2024-10-07", nicht_verifiziert: 800 },
  { date: "2024-10-28", nicht_verifiziert: 700 },
  { date: "2024-10-29", nicht_verifiziert: 800 },
  { date: "2024-10-22", nicht_verifiziert: 700 },
  { date: "2024-10-21", nicht_verifiziert: 800 },
  { date: "2024-10-24", nicht_verifiziert: 600 },
  { date: "2024-10-25", nicht_verifiziert: 700 },
  { date: "2024-10-26", nicht_verifiziert: 800 },
];
const staticDataSupprtTicket = [
  { date: "2024-10-07", support_tickets: 600 },
  { date: "2024-10-08", support_tickets: 600 },
  { date: "2024-09-25", support_tickets: 700 },
  { date: "2024-09-27", support_tickets: 800 },
  { date: "2024-09-28", support_tickets: 700 },
  { date: "2024-09-29", support_tickets: 800 },
  { date: "2024-09-22", support_tickets: 700 },
  { date: "2024-09-21", support_tickets: 800 },
  { date: "2024-08-24", support_tickets: 600 },
  { date: "2024-05-25", support_tickets: 700 },
  { date: "2024-03-26", support_tickets: 800 },
];
// Sample data for registered schools
const schoolData = [
  { month: "January", newRegistrations: 5, totalRegistrations: 5 },
  { month: "February", newRegistrations: 10, totalRegistrations: 15 },
  { month: "March", newRegistrations: 7, totalRegistrations: 22 },
  { month: "April", newRegistrations: 12, totalRegistrations: 34 },
  { month: "May", newRegistrations: 15, totalRegistrations: 49 },
  { month: "June", newRegistrations: 8, totalRegistrations: 57 },
  { month: "July", newRegistrations: 14, totalRegistrations: 71 },
  { month: "August", newRegistrations: 20, totalRegistrations: 91 },
  { month: "September", newRegistrations: 18, totalRegistrations: 109 },
  { month: "October", newRegistrations: 22, totalRegistrations: 131 },
  { month: "November", newRegistrations: 17, totalRegistrations: 148 },
  { month: "December", newRegistrations: 25, totalRegistrations: 173 },
];
// Sample data for email slots (static for now, replace with dynamic data if needed)
const emailSlotData = [
  { month: "January", newSlots: 50, totalSlots: 150 },
  { month: "February", newSlots: 80, totalSlots: 230 },
  { month: "March", newSlots: 100, totalSlots: 330 },
  { month: "April", newSlots: 70, totalSlots: 400 },
  { month: "May", newSlots: 90, totalSlots: 490 },
  { month: "June", newSlots: 110, totalSlots: 600 },
  { month: "July", newSlots: 60, totalSlots: 660 },
  { month: "August", newSlots: 120, totalSlots: 780 },
  { month: "September", newSlots: 90, totalSlots: 870 },
  { month: "October", newSlots: 130, totalSlots: 1000 },
  { month: "November", newSlots: 80, totalSlots: 1080 },
  { month: "December", newSlots: 100, totalSlots: 1180 },
];
// subs status data
const subscriptionData = [
  { name: "Active", value: 400 },
  { name: "Expired", value: 100 },
  { name: "Upcoming Renewal", value: 50 },
];
// subs status data
const emailPerSchool = [
  { name: "Total", value: 400 },
  { name: "Used", value: 100 },
];
// teacher and student acitvity
const activityData = [
  { date: "2024-09-01", teachers: 20, students: 0 },
  { date: "2024-10-07", teachers: 22, students: 45 },
  { date: "2024-10-04", teachers: 2, students: 78 },
  { date: "2024-10-5", teachers: 22, students: 86 },
  { date: "2024-10-2", teachers: 20, students: 25 },
  { date: "2024-10-20", teachers: 12, students: 58 },
  { date: "2024-10-12", teachers: 25, students: 10 },
  { date: "2024-10-22", teachers: 28, students: 15 },
];
// number of verified and not verified emails
const staticDataForEmails = [
  { date: "2024-10-08", nicht_verifiziert: 60, verifiedEmails: 50 },
  { date: "2024-10-02", nicht_verifiziert: 70, verifiedEmails: 30 },
  { date: "2024-10-07", nicht_verifiziert: 80, verifiedEmails: 20 },
  { date: "2024-10-28", nicht_verifiziert: 70, verifiedEmails: 10 },
  { date: "2024-10-29", nicht_verifiziert: 80, verifiedEmails: 60 },
  { date: "2024-10-22", nicht_verifiziert: 70, verifiedEmails: 40 },
  { date: "2024-10-21", nicht_verifiziert: 80, verifiedEmails: 56 },
  { date: "2024-10-24", nicht_verifiziert: 60, verifiedEmails: 53 },
  { date: "2024-10-25", nicht_verifiziert: 70, verifiedEmails: 52 },
  { date: "2024-10-26", nicht_verifiziert: 80, verifiedEmails: 50 },
];
const staticDataForSentAndReceivedEmail = [
  { date: "2024-10-08", sentEmails: 300, receivedEmails: 250 },
  { date: "2024-10-02", sentEmails: 400, receivedEmails: 320 },
  { date: "2024-10-07", sentEmails: 400, receivedEmails: 820 },
  { date: "2024-10-28", sentEmails: 40, receivedEmails: 620 },
  { date: "2024-10-29", sentEmails: 400, receivedEmails: 620 },
  { date: "2024-10-22", sentEmails: 500, receivedEmails: 120 },
  { date: "2024-10-21", sentEmails: 300, receivedEmails: 220 },
  { date: "2024-10-24", sentEmails: 600, receivedEmails: 320 },
  { date: "2024-10-25", sentEmails: 200, receivedEmails: 520 },
  { date: "2024-10-26", sentEmails: 700, receivedEmails: 420 },
];
const participationData = [
  { date: "2024-10-08", assignmentSubmissions: 30, attendance: 25 },
  { date: "2024-10-02", assignmentSubmissions: 40, attendance: 32 },
  { date: "2024-10-07", assignmentSubmissions: 45, attendance: 82 },
  { date: "2024-10-28", assignmentSubmissions: 42, attendance: 60 },
  { date: "2024-10-29", assignmentSubmissions: 50, attendance: 62 },
  { date: "2024-10-22", assignmentSubmissions: 52, attendance: 12 },
  { date: "2024-10-21", assignmentSubmissions: 30, attendance: 22 },
  { date: "2024-10-24", assignmentSubmissions: 60, attendance: 32 },
  { date: "2024-10-25", assignmentSubmissions: 20, attendance: 52 },
  { date: "2024-10-26", assignmentSubmissions: 70, attendance: 42 },
  // Add more data points as needed
];
const salesData = [
  {
    name: "Jan 23",
    Ausgaben: 38,
    Einnahmen: 12,
  },
  {
    name: "Feb 23",
    Ausgaben: 40,
    Einnahmen: 32,
  },
  {
    name: "Mar 23",
    Ausgaben: 58,
    Einnahmen: 43,
  },
  {
    name: "Apr 23",
    Ausgaben: 14,
    Einnahmen: 34,
  },
  {
    name: "May 23",
    Ausgaben: 11,
    Einnahmen: 58,
  },
  {
    name: "Jun 23",
    Ausgaben: 36,
    Einnahmen: 26,
  },
  {
    name: "Jul 23",
    Ausgaben: 27,
    Einnahmen: 39,
  },
  {
    name: "Aug 23",
    Ausgaben: 37,
    Einnahmen: 50,
  },
  {
    name: "Sep 23",
    Ausgaben: 28,
    Einnahmen: 38,
  },
  {
    name: "Oct 23",
    Ausgaben: 52,
    Einnahmen: 30,
  },
  {
    name: "Nov 23",
    Ausgaben: 19,
    Einnahmen: 9,
  },
  {
    name: "Dec 23",
    Ausgaben: 32,
    Einnahmen: 42,
  },
];
const emailsPerSchool = [
  {
    name: "Jan 23",
    emails: 38,
    school_title: 12,
  },
  {
    name: "Feb 23",
    emails: 40,
    school_title: 32,
  },
  {
    name: "Mar 23",
    emails: 58,
    school_title: 43,
  },
  {
    name: "Apr 23",
    emails: 14,
    school_title: 34,
  },
  {
    name: "May 23",
    emails: 11,
    school_title: 58,
  },
  {
    name: "Jun 23",
    emails: 36,
    school_title: 26,
  },
  {
    name: "Jul 23",
    emails: 27,
    school_title: 39,
  },
  {
    name: "Aug 23",
    emails: 37,
    school_title: 50,
  },
  {
    name: "Sep 23",
    emails: 28,
    school_title: 38,
  },
  {
    name: "Oct 23",
    emails: 52,
    school_title: 30,
  },
  {
    name: "Nov 23",
    emails: 19,
    school_title: 9,
  },
  {
    name: "Dec 23",
    emails: 32,
    school_title: 42,
  },
];
// onboarding instructions
const onboarding_dashboard = [
  {
    title: "Datumsbereichsfilter",
    description:
      "Eine Übersicht des Datumsbereichsfilters, der es den Benutzern ermöglicht, Daten für benutzerdefinierte Zeiträume zu filtern.",
  },
  {
    title: "Übersicht der Hauptstatistiken",
    description:
      "Beschreibt die Hauptstatistik-Karten, die wichtige Kennzahlen anzeigen, wie die Gesamtanzahl der gesendeten E-Mails, neu registrierte Schulen und Support-Tickets. Dieser Abschnitt ermöglicht auch die Datensegmentierung basierend auf verschiedenen Zeiträumen.",
  },
  {
    title: "Unbestätigte E-Mails",
    description:
      "Erläutert, wie viele unbestätigte E-Mails angezeigt werden, dargestellt durch ein Balkendiagramm. Es kann nach täglichen, wöchentlichen oder monatlichen Ansichten gefiltert werden.",
  },
  {
    title: "Daten zu gesendeten E-Mails",
    description:
      "Zeigt die Gesamtzahl der innerhalb eines bestimmten Zeitraums gesendeten E-Mails an, mit einem Dropdown-Menü zur Auswahl zwischen verschiedenen Datumsbereichen. Enthält ein Liniendiagramm zur Visualisierung der E-Mail-Daten.",
  },
];

const onboarding_dashboard_last = [
  {
    title: "Mailgun E-Mail-Nutzung",
    description:
      "Bietet Statistiken über über Mailgun gesendete E-Mails, dargestellt als tägliche, wöchentliche oder monatliche Daten in einem interaktiven Balkendiagramm.",
  },
  {
    title: "Neu registrierte Schulen",
    description:
      "Verfolgt neu registrierte Schulen über die Zeit, dargestellt in einem Balkendiagramm.",
  },
  {
    title: "Support-Tickets",
    description:
      "Zeigt die Gesamtanzahl der Support-Tickets und Trends über die Zeit in einem Balkendiagramm an, mit täglichen, wöchentlichen oder monatlichen Filtern.",
  },
  {
    title: "Offene Support-Tickets",
    description:
      "Hebt die Anzahl der derzeit offenen Support-Tickets hervor, mit Filteroptionen basierend auf Zeiträumen.",
  },
  {
    title: "Registrierte Klassen",
    description:
      "Verfolgt die Anzahl der registrierten Klassen über die Zeit und verwendet ein Balkendiagramm zur Darstellung der Trends.",
  },
];

const onboarding_allSchool = [
  {
    title: "Nach Zeiträumen filtern",
    description:
      "Verwenden Sie die Schaltflächen oben, um Daten nach bestimmten Zeiträumen zu filtern: 'Tag' zeigt Schulen, die heute registriert wurden, 'Woche' zeigt Schulen, die diese Woche registriert wurden, und 'Monat' zeigt Schulen, die diesen Monat registriert wurden. Sie können den Filter zurücksetzen, indem Sie auf das 'X'-Symbol klicken.",
  },
  {
    title: "Benutzerdefinierter Datumsbereich",
    description:
      "Verwenden Sie den Datumsbereichsfilter auf der rechten Seite, um Schulen basierend auf einem bestimmten benutzerdefinierten Datumsbereich zu filtern.",
  },
  {
    title: "Suchfunktion",
    description:
      "Die Suchleiste ermöglicht es Ihnen, schnell eine bestimmte Schule zu finden, indem Sie den Namen der Schule oder andere verwandte Schlüsselwörter eingeben.",
  },
];

const onboarding_allSchool_last = [
  {
    title: "Schuldaten Tabelle",
    description:
      "Die Tabelle zeigt wichtige Details wie: Schulname, Kontaktperson, E-Mail-Adresse, Telefonnummer, Abonnementsstatus, Anzahl der Klassen und Registrierungsdatum an. Sie können durch die Seiten mit den Steuerungen zur Seitennavigation unten blättern.",
  },
  {
    title: "Seitennavigation",
    description:
      "Navigieren Sie durch die Tabellenseiten mit den Pfeiltasten links und rechts, um mehr Daten anzuzeigen.",
  },
];

const onboarding_addSchool = [
  {
    title: "Schulname eingeben",
    description: "Geben Sie den Namen der Schule im Feld 'Schulname' ein.",
  },
  {
    title: "Kontaktperson",
    description: "Geben Sie den Namen der Kontaktperson für die Schule an.",
  },
  {
    title: "E-Mail-Adresse",
    description: "Geben Sie die Kontakt-E-Mail-Adresse ein.",
  },
  {
    title: "Telefonnummer",
    description: "Geben Sie die Telefonnummer der Schule ein.",
  },
];

const onboarding_addSchool_last = [
  {
    title: "Adresse",
    description: "Geben Sie die Adresse der Schule an.",
  },
  {
    title: "Abonnementsstatus auswählen",
    description: "Wählen Sie den Abonnementsstatus aus dem Dropdown-Menü.",
  },
  {
    title: "Anzahl der Klassen",
    description: "Geben Sie die Gesamtanzahl der Klassen an.",
  },
  {
    title: "Registrierungsdatum",
    description: "Geben Sie das Registrierungsdatum der Schule ein.",
  },
  {
    title: "Abbrechen oder Einreichen",
    description:
      "Klicken Sie auf 'Stornieren', um abzubrechen, oder auf 'Einreichen', um das Formular abzusenden.",
  },
];

const onboarding_schoolStructure = [
  {
    title: "Wählen Sie Ihren Ansichtsmodus",
    description:
      "Wechseln Sie mit den Schaltflächen oben zwischen Bearbeiten und Visualisieren.",
  },
  {
    title: "Vorlage herunterladen",
    description:
      "Laden Sie die bereitgestellte Vorlage zum Importieren bestehender Klassenstrukturen herunter, indem Sie auf 'Vorlage herunterladen' klicken.",
  },
  {
    title: "Klassendaten hochladen",
    description:
      "Verwenden Sie die Dateiauswahl, um eine Excel-Datei mit Ihren Klassen- und Ausschussinformationen hochzuladen.",
  },
  {
    title: "Klassenstruktur bearbeiten",
    description:
      "Fügen Sie Klassen und Tiefen mit den entsprechenden Schaltflächen hinzu oder entfernen Sie sie. Geben Sie Klassennamen und Tiefendetails (Bezeichnung und Schüleranzahl) ein.",
  },
];

const onboarding_schoolStructure_last = [
  {
    title: "Ausschüsse verwalten",
    description:
      "Überprüfen oder deaktivieren Sie die verfügbaren Ausschüsse oder fügen Sie bei Bedarf benutzerdefinierte Ausschüsse hinzu.",
  },
  {
    title: "Änderungen speichern",
    description:
      "Klicken Sie auf die Schaltfläche 'Absenden', um Ihre Klassenstruktur und Ausschusseinstellungen zu speichern.",
  },
  {
    title: "Visualisieren Sie Ihre Struktur",
    description:
      "Wechseln Sie in den Visualisierungsmodus, um eine grafische Darstellung Ihrer Schulstruktur zu sehen.",
  },
];

const onboarding_Structure_last = [
  {
    title: "Klassenstruktur bearbeiten",
    description:
      "Fügen Sie Klassen und Tiefen mit den entsprechenden Schaltflächen hinzu oder entfernen Sie sie. Geben Sie Klassennamen und Tiefendetails (Bezeichnung und Schüleranzahl) ein.",
  },
  {
    title: "Ausschüsse verwalten",
    description:
      "Überprüfen oder deaktivieren Sie die verfügbaren Ausschüsse oder fügen Sie bei Bedarf benutzerdefinierte Ausschüsse hinzu.",
  },
  {
    title: "Änderungen speichern",
    description:
      "Klicken Sie auf die Schaltfläche 'Absenden', um Ihre Klassenstruktur und Ausschusseinstellungen zu speichern.",
  },
  {
    title: "Visualisieren Sie Ihre Struktur",
    description:
      "Wechseln Sie in den Visualisierungsmodus, um eine grafische Darstellung Ihrer Schulstruktur zu sehen.",
  },
];

const onboarding_Structure = [
  {
    title: "Wählen Sie Ihren Ansichtsmodus",
    description:
      "Wechseln Sie mit den Schaltflächen oben zwischen Bearbeiten und Visualisieren.",
  },
  {
    title: "Vorlage herunterladen",
    description:
      "Laden Sie die bereitgestellte Vorlage zum Importieren bestehender Klassenstrukturen herunter, indem Sie auf 'Vorlage herunterladen' klicken.",
  },
  {
    title: "Klassendaten hochladen",
    description:
      "Verwenden Sie die Dateiauswahl, um eine Excel-Datei mit Ihren Klassen- und Ausschussinformationen hochzuladen.",
  },
];

const formInstructions = [
  {
    title: "Dokumenttitel",
    description: "Geben Sie einen Titel für Ihr Dokument ein.",
  },
  {
    title: "Beschreibung",
    description: "Geben Sie eine kurze Beschreibung des Dokuments an.",
  },
  {
    title: "Tags",
    description:
      "Fügen Sie relevante Tags hinzu, um Ihr Dokument zu kategorisieren.",
  },
  {
    title: "Datei hochladen",
    description:
      "Ziehen Sie eine PNG-, JPG-, JPEG- oder PDF-Datei hinein oder wählen Sie sie aus.",
  },
  {
    title: "Absenden",
    description:
      "Überprüfen Sie die Details und reichen Sie Ihr Dokument zur Bearbeitung ein.",
  },
  {
    title: "Einreichungen anzeigen",
    description: "Überprüfen Sie die Tabelle für Ihre eingereichten Dokumente.",
  },
  {
    title: "Seitenumbruch",
    description: "Navigieren Sie durch Seiten von Dokumenteneinreichungen.",
  },
];

const formInstructions_last = [
  {
    title: "Props",
    description:
      "Übergeben Sie erforderliche Daten, Seitenzahlen und Ereignis-Handler für Benutzerinteraktionen.",
  },
  {
    title: "Zustand",
    description:
      "Verwalten Sie den Zustand für gefilterte Daten, ausgewählte Filter, Datumsbereich, Dateiansichten und Sichtbarkeit von Modalen.",
  },
  {
    title: "Datumsbereich",
    description:
      "Verwenden Sie einen Datumsauswähler zur Auswahl des Datumsbereichs und wenden Sie die Datumsfilterlogik an.",
  },
  {
    title: "Filterlogik",
    description:
      "Wenden Sie Filter basierend auf ausgewählter Priorität, Status und dem gewählten Datumsbereich an, unter Verwendung von Zustands- und Effekthooks.",
  },
  {
    title: "Zeilenklick",
    description:
      "Aktivieren Sie die detaillierte Ansichtsfunktion, indem Sie auf Zeilen klicken und die relevanten Informationen öffnen.",
  },
  {
    title: "Bearbeiten",
    description:
      "Rufen Sie eine Bearbeitungsoberfläche auf, um die ausgewählten Daten zu ändern.",
  },
  {
    title: "Löschen",
    description:
      "Bieten Sie die Möglichkeit, einen Eintrag zu löschen und die Daten anschließend zu aktualisieren.",
  },
  {
    title: "Dateiverwaltung",
    description:
      "Überprüfen Sie den Dateityp (z. B. PDF) und bieten Sie Optionen zum Öffnen der Datei in einem Modal oder neuen Tab an.",
  },
  {
    title: "Tabelle",
    description:
      "Zeigen Sie die gefilterten Daten in Tabellenform mit Spalten für ID, Titel, Datei, Datum und verfügbaren Aktionen an.",
  },
  {
    title: "Seitenumbruch",
    description:
      "Implementieren Sie Seitensteuerelemente, um durch Seiten von Daten zu navigieren, einschließlich nächster, vorheriger und spezifischer Seiten-Schaltflächen.",
  },
];

const onboarding_agreements = [
  {
    title: "Seitenübersicht",
    description:
      "Verwalten und anzeigen Sie Vereinbarungen und hochgeladene Dokumente an einem Ort.",
  },
  {
    title: "Datei hinzufügen",
    description:
      "Verwenden Sie das Eingabefeld, um ein neues Dokument hinzuzufügen, indem Sie seinen Namen eingeben.",
  },
  {
    title: "Dokumente anzeigen",
    description:
      "Klicken Sie auf den Dokumentnamen, um die Details anzuzeigen oder es herunterzuladen.",
  },
];

const onboarding_agreements_last = [
  {
    title: "Dateidetails",
    description:
      "Sehen Sie die Dokumentgröße und das Upload-Datum in der Tabelle.",
  },
  {
    title: "Einstellungen",
    description:
      "Verwenden Sie das Einstellungssymbol, um Dokumentoptionen zu verwalten.",
  },
  {
    title: "Seitenumbruch",
    description:
      "Navigieren Sie bei Bedarf durch mehrere Seiten von Dokumenten.",
  },
];

const onboarding_changelog = [
  {
    title: "Änderungsprotokoll Übersicht",
    description:
      "Sehen Sie sich die Änderungsprotokolle für alle aktuellen Updates und Veröffentlichungen an.",
  },
  {
    title: "Suchfunktion",
    description:
      "Verwenden Sie die Suchleiste, um schnell spezifische Versionen oder Änderungen zu finden.",
  },
  {
    title: "Nach Datum filtern",
    description:
      "Klicken Sie auf 'Tag', 'Woche' oder 'Monat', um Protokolle nach Zeiträumen zu filtern.",
  },
  {
    title: "Benutzerdefinierter Datumsbereich",
    description:
      "Wählen Sie einen benutzerdefinierten Datumsbereich mit dem Datumswähler aus, um Ihre Suche einzugrenzen.",
  },
];
const onboarding_changelog_last = [
  {
    title: "Seiten navigieren",
    description:
      "Verwenden Sie die vorherigen und nächsten Schaltflächen, um durch verschiedene Seiten zu navigieren.",
  },
  {
    title: "Filter zurücksetzen",
    description:
      "Aktive Filter zurücksetzen, indem Sie auf das Schließen-Symbol neben den Filter-Schaltflächen klicken.",
  },
  {
    title: "Änderungsprotokolle sortieren",
    description:
      "Sortieren Sie die Änderungsprotokolle nach Datum, Typ oder Version mit dem Dropdown-Menü zur Sortierung.",
  },
  {
    title: "Änderungsprotokolle exportieren",
    description:
      "Laden Sie die Änderungsprotokolle als CSV-Datei für Ihre Unterlagen herunter.",
  },
  {
    title: "Wichtige Änderungen als Lesezeichen speichern",
    description:
      "Verwenden Sie das Sternsymbol, um Änderungen zu markieren, die Sie später erneut besuchen möchten.",
  },
];
const onboarding_email = [
  {
    title: "E-Mail-Protokoll erkunden",
    description:
      "Sehen Sie sich alle Ihre E-Mail-Protokolle und deren Details an.",
  },
  {
    title: "Nach Datum filtern",
    description:
      "Verwenden Sie den Datumsbereichs-Auswähler, um Protokolle nach bestimmten Daten zu filtern.",
  },
  {
    title: "Suchfunktion",
    description:
      "Geben Sie in der Suchleiste ein, um spezifische E-Mail-Protokolle nach Empfänger oder Ereignis zu finden.",
  },
];
const onboarding_email_last = [
  {
    title: "Protokolle nach Zeitraum anzeigen",
    description:
      "Klicken Sie auf die Schaltflächen 'Tag', 'Woche' oder 'Monat', um Protokolle nach dem ausgewählten Zeitraum zu filtern.",
  },
  {
    title: "Seiten navigieren",
    description:
      "Verwenden Sie die Paginierungssteuerelemente, um durch mehrere Seiten von Protokollen zu blättern.",
  },
  {
    title: "Filter zurücksetzen",
    description:
      "Klicken Sie auf das Schließen-Symbol, um die aktiven Filter zurückzusetzen und alle Protokolle erneut anzuzeigen.",
  },
];
const onboarding_system_last = [
  {
    title: "Zeitrahmen auswählen",
    description:
      "Wählen Sie einen Zeitraum (Tag, Woche, Monat), um Protokolle zu filtern.",
  },
  {
    title: "Detaillierte Einträge anzeigen",
    description:
      "Klicken Sie auf einen Protokolleintrag für weitere Details zum Ereignis.",
  },
  {
    title: "Protokolle exportieren",
    description:
      "Verwenden Sie die Exportfunktion, um Protokolldaten für Analysen herunterzuladen.",
  },
];
const onboarding_system = [
  {
    title: "Protokolle filtern",
    description:
      "Verwenden Sie die Suchleiste, um Protokolle nach Benutzer-E-Mail oder Ereignisbeschreibung zu filtern.",
  },
  {
    title: "Datumsbereich auswählen",
    description:
      "Nutzen Sie den Datumswähler, um einen Datumsbereich für Ihre Protokolleinträge festzulegen.",
  },
  {
    title: "Seiten navigieren",
    description:
      "Verwenden Sie die Paginierungssteuerelemente, um mehr Protokolle über die ursprüngliche Menge hinaus anzuzeigen.",
  },
];
const onboarding_broadcast = [
  {
    title: "Willkommen auf der Senderseite!",
    description:
      "Diese Seite ermöglicht es Ihnen, Nachrichten effektiv an Benutzer oder Gruppen zu senden.",
  },
  {
    title: "Betreff ausfüllen",
    description:
      "Geben Sie einen klaren und prägnanten Betreff für Ihre Nachricht im Feld 'Betreff' ein.",
  },
  {
    title: "Ihre Nachricht verfassen",
    description:
      "Schreiben Sie Ihre Nachricht im Textbereich 'Nachricht'. Stellen Sie sicher, dass sie informativ und auf den Punkt gebracht ist.",
  },
];
const onboarding_broadcast_last = [
  {
    title: "Empfänger auswählen",
    description:
      "Wählen Sie aus dem Dropdown-Menü 'Empfänger', wer Ihre Nachricht erhalten soll.",
  },
  {
    title: "Nachrichtentyp wählen",
    description:
      "Wählen Sie den Typ der Benachrichtigung (z. B. 'Warnung' oder 'Info'), um Kontext zu geben.",
  },
  {
    title: "Ihre Nachricht senden",
    description:
      "Klicken Sie auf die Schaltfläche 'Absenden', um Ihre Nachricht zu senden, oder auf 'Abbrechen', um sie zu verwerfen.",
  },
  {
    title: "Auf Fehler prüfen",
    description:
      "Achten Sie auf Fehlermeldungen, wenn erforderliche Felder fehlen oder falsch sind.",
  },
];
const onboarding_classes = [
  {
    title: "Klassen anzeigen",
    description:
      "Oben auf der Seite sehen Sie eine Liste der derzeit im System verfügbaren Klassen. Jeder Eintrag zeigt den Klassennamen, die E-Mail-Adresse, das Passwort und das Registrierungsdatum an.",
  },
  {
    title: "Nach Datum filtern",
    description:
      "Verwenden Sie die Datumsbuttons, um Klassen nach Tag, Woche oder Monat zu filtern. Der aktive Filter wird blau hervorgehoben. Um den Filter zurückzusetzen, klicken Sie auf das Schließen-Symbol neben der aktiven Schaltfläche.",
  },
  {
    title: "Datumsbereich auswählen",
    description:
      "Sie können einen spezifischen Datumsbereich mit dem Datumswähler auswählen. Klicken Sie auf das Kalendersymbol, um ihn zu öffnen, und wählen Sie dann Ihr gewünschtes Start- und Enddatum aus.",
  },
  {
    title: "Suchfunktion",
    description:
      "Verwenden Sie das Suchfeld, um eine spezifische Klasse nach ihrem Namen zu finden. Geben Sie einfach den Klassennamen ein, und die Liste wird automatisch aktualisiert, um übereinstimmende Ergebnisse anzuzeigen.",
  },
  {
    title: "Paginierung",
    description:
      "Wenn es viele Klassen gibt, können Sie mit den Schaltflächen Vorherige und Nächste durch die Seiten navigieren.",
  },
  {
    title: "Daten synchronisieren",
    description:
      "Um Klassendaten (wie E-Mail und Passwort) zu synchronisieren, klicken Sie auf die Synchronisierungsschaltfläche. Dadurch werden die Daten für erforderliche Updates vorbereitet.",
  },
  {
    title: "Fragen oder Hilfe",
    description:
      "Wenn Sie Fragen haben, wenden Sie sich bitte an Ihren Administrator!",
  },
];

const onboarding_classes_last = [
  {
    title: "Suchfunktion",
    description:
      "Verwenden Sie das Suchfeld, um eine bestimmte Klasse nach ihrem Namen zu finden. Geben Sie einfach den Klassennamen ein, und die Liste wird automatisch aktualisiert, um passende Ergebnisse anzuzeigen.",
  },
  {
    title: "Pagination",
    description:
      "Wenn es viele Klassen gibt, können Sie mit den Schaltflächen Vorherige und Nächste durch die Seiten navigieren.",
  },
  {
    title: "Daten synchronisieren",
    description:
      "Um Klassendaten (wie E-Mail und Passwort) zu synchronisieren, klicken Sie auf die Schaltfläche synchronisieren. Dies bereitet die Daten für erforderliche Updates vor.",
  },
  {
    title: "Fragen oder Hilfe",
    description:
      "Wenn Sie Fragen haben, können Sie sich gerne an Ihren Administrator wenden!",
  },
];

const onboarding_class_manage = [
  {
    title: "Verstehen der Benutzeroberfläche",
    description:
      "Machen Sie sich mit dem Layout der Seite zur Klassenverwaltung vertraut. Sie finden Eingabefelder für die Eingabe von Administratorinformationen, Dropdowns zur Auswahl von E-Mail- und Klassennamen sowie Aktionsschaltflächen zum Einreichen Ihrer Informationen.",
  },
  {
    title: "Ausfüllen des Formulars",
    description:
      "Geben Sie den Namen des Administrators in das bereitgestellte Eingabefeld ein. Wählen Sie die E-Mail des Administrators aus dem Dropdown-Menü und wählen Sie den Klassennamen aus der Dropdown-Liste aus. Stellen Sie sicher, dass alle erforderlichen Felder ausgefüllt sind.",
  },
];

const onboarding_class_manage_last = [
  {
    title: "Validierungsfeedback",
    description:
      "Fehlermeldungen erscheinen in Rot unter den jeweiligen Feldern, wenn Sie erforderliche Felder auslassen. Stellen Sie sicher, dass Sie alle Fehler beheben, bevor Sie absenden.",
  },
  {
    title: "Formular einreichen",
    description:
      "Klicken Sie auf die Schaltfläche 'Einreichen', um Ihre Daten zu speichern, nachdem Sie die erforderlichen Felder ausgefüllt haben. Verwenden Sie die Schaltfläche 'Stornieren', um das Formular bei Bedarf zurückzusetzen.",
  },
  {
    title: "Bestätigung",
    description:
      "Nach erfolgreicher Einreichung erhalten Sie eine Bestätigungsnachricht, die anzeigt, dass Ihre Daten gespeichert wurden. Relevante Fehlermeldungen helfen Ihnen im Falle von Problemen.",
  },
];

const onboarding_ticket = [
  {
    title: "Ticket erstellen",
    description:
      "Füllen Sie die erforderlichen Felder aus: Betreff, Beschreibung und Priorität. Fügen Sie alle erforderlichen Dateien hinzu, indem Sie sie ziehen und ablegen oder von Ihrem Gerät auswählen. Klicken Sie auf die Schaltfläche Einreichen, um Ihr Ticket zu erstellen.",
  },
  {
    title: "Eingereichte Tickets anzeigen",
    description:
      "Nach der Einreichung wird Ihr Ticket in der Liste unten angezeigt. Sie können nach spezifischen Tickets filtern oder suchen, um sie leicht zu finden.",
  },
  {
    title: "Ticket bearbeiten",
    description:
      "Um ein vorhandenes Ticket zu ändern, wählen Sie es aus der Liste aus. Nehmen Sie die erforderlichen Änderungen vor und reichen Sie das Formular erneut ein.",
  },
  {
    title: "Ticketstatus überprüfen",
    description:
      "Jedes Ticket zeigt seinen aktuellen Status (z. B. Offen, In Bearbeitung, Geschlossen) an, um Sie über den Fortschritt auf dem Laufenden zu halten.",
  },
  {
    title: "Support erhalten",
    description:
      "Wenn Sie Fragen oder Probleme haben, können Sie sich über die bereitgestellten Support-Kanäle an uns wenden.",
  },
];

const onboarding_ticket_last = [
  {
    title: "Layout verstehen",
    description:
      "Machen Sie sich mit den verschiedenen Abschnitten der Ticketseite vertraut, einschließlich Filter, Tickettabelle und Pagination.",
  },
  {
    title: "Tickets filtern",
    description:
      "Verwenden Sie die Dropdowns, um Tickets nach Priorität und Status zu filtern, und wählen Sie einen Datumsbereich aus, um Tickets aus bestimmten Zeiträumen anzuzeigen.",
  },
  {
    title: "Ticketdetails anzeigen",
    description:
      "Klicken Sie auf eine Ticketzeile, um detaillierte Informationen in einem Modal anzuzeigen.",
  },
  {
    title: "Tickets bearbeiten und löschen",
    description:
      "Administratoren können Tickets mit den entsprechenden Symbolen neben jedem Ticket bearbeiten oder löschen.",
  },
  {
    title: "Dateiverwaltung",
    description:
      "Greifen Sie auf angehängte Dateien zu, indem Sie auf das Dateisymbol klicken. PDF-Dateien werden in einem Viewer geöffnet, während andere Typen in einem neuen Tab geöffnet werden.",
  },
  {
    title: "Pagination-Steuerungen",
    description:
      "Navigieren Sie durch die Ticketseiten mit den Schaltflächen vorherige, nächste und nummerierte Seiten.",
  },
  {
    title: "Ladezustand",
    description:
      "Ein Lade-Symbol zeigt an, dass Ticketdaten abgerufen werden. Warten Sie, bis das Laden abgeschlossen ist.",
  },
];

const onboarding_faq = [
  {
    title: "Willkommen im FAQ-Bereich!",
    description:
      "Diese Seite ist dazu gedacht, Ihnen bei der Suche nach Antworten auf häufige Fragen zu helfen.",
  },
  {
    title: "Kategorien durchsuchen",
    description:
      "Verwenden Sie die Registerkarten oben auf der Seite, um zwischen verschiedenen Kategorien zu wechseln: Allgemein, Abrechnung und Technischer Support.",
  },
  {
    title: "Suchfunktion",
    description:
      "Suchen Sie nach bestimmten Fragen mit der Suchleiste. Geben Sie Schlüsselwörter zu Ihrer Anfrage ein, um die Liste zu filtern.",
  },
];

const onboarding_faq_last = [
  {
    title: "Fragen erweitern",
    description:
      "Klicken Sie auf das '+'-Symbol, um eine Frage zu erweitern und die Antwort anzuzeigen. Klicken Sie auf das '-' -Symbol, um sie zu reduzieren.",
  },
  {
    title: "Fragen bearbeiten oder löschen",
    description:
      "Als Administrator können Sie Fragen mit den Stift- und Papierkorb-Symbolen neben jeder Frage bearbeiten oder löschen.",
  },
  {
    title: "Fragen bearbeiten",
    description:
      "Klicken Sie auf das Bearbeitungssymbol, um ein Modell zu öffnen, um Änderungen an der Frage und ihrer Antwort vorzunehmen.",
  },
  {
    title: "Brauchen Sie Hilfe?",
    description:
      "Wenn Sie weitere Fragen haben oder Unterstützung benötigen, wenden Sie sich an unser Support-Team.",
  },
];

const onboarding_setting = [
  {
    title: "Profilinformationen",
    description:
      "Aktualisieren Sie Ihre persönlichen Informationen wie Vorname, Nachname, E-Mail-Adresse und Schulzugehörigkeit. Stellen Sie sicher, dass Sie alle erforderlichen Felder ausfüllen, die mit einem Sternchen gekennzeichnet sind.",
  },
  {
    title: "Passwortverwaltung",
    description:
      "Ändern Sie Ihr Passwort, um Ihr Konto abzusichern. Sie müssen Ihr aktuelles Passwort zusammen mit Ihrem neuen Passwort eingeben. Stellen Sie sicher, dass Ihr neues Passwort die Sicherheitsanforderungen erfüllt.",
  },
  {
    title: "Zwei-Faktor-Authentifizierung (2FA)",
    description:
      "Verbessern Sie die Sicherheit Ihres Kontos, indem Sie die Zwei-Faktor-Authentifizierung aktivieren. Wenn sie aktiviert ist, müssen Sie Ihre Identität bei der Anmeldung mit einer zweiten Methode (z. B. einer mobilen App) bestätigen.",
  },
  {
    title: "Schriftgröße anpassen",
    description:
      "Passen Sie die Schriftgröße an Ihre Lesepräferenzen an. Verwenden Sie die bereitgestellten Steuerungen, um die Textgröße in der Anwendung zu erhöhen oder zu verringern.",
  },
  {
    title: "Änderungen einreichen",
    description:
      "Stellen Sie sicher, dass Sie auf die Schaltfläche 'Einreichen' klicken, um Ihre Änderungen zu speichern, nachdem Sie Aktualisierungen vorgenommen haben. Wenn Sie Ihre Änderungen verwerfen möchten, klicken Sie auf 'Abbrechen.'",
  },
];

const onboarding_setting_last = [
  {
    title: "Zwei-Faktor-Authentifizierung (2FA)",
    description:
      "Verbessern Sie die Sicherheit Ihres Kontos, indem Sie die Zwei-Faktor-Authentifizierung aktivieren. Wenn sie aktiviert ist, müssen Sie Ihre Identität bei der Anmeldung mit einer zweiten Methode (z. B. einer mobilen App) bestätigen.",
  },
  {
    title: "Schriftgröße anpassen",
    description:
      "Passen Sie die Schriftgröße an Ihre Lesepräferenzen an. Verwenden Sie die bereitgestellten Steuerungen, um die Textgröße in der Anwendung zu erhöhen oder zu verringern.",
  },
  {
    title: "Änderungen einreichen",
    description:
      "Stellen Sie sicher, dass Sie auf die Schaltfläche 'Einreichen' klicken, um Ihre Änderungen zu speichern, nachdem Sie Aktualisierungen vorgenommen haben. Wenn Sie Ihre Änderungen verwerfen möchten, klicken Sie auf 'Abbrechen.'",
  },
];

export default {
  data,
  dataSupportTicket,
  dataEmails,
  staticData,
  staticDataForRegisteredClasses,
  incomeData,
  staticDataSchool,
  staticDataForSentEmail,
  staticDataEmail,
  staticDataSupprtTicket,
  schoolData,
  emailSlotData,
  subscriptionData,
  activityData,
  staticDataForEmails,
  staticDataForSentAndReceivedEmail,
  participationData,
  salesData,
  emailsPerSchool,
  emailPerSchool,
  onboarding_dashboard,
  onboarding_dashboard_last,
  onboarding_allSchool,
  onboarding_allSchool_last,
  onboarding_addSchool,
  onboarding_addSchool_last,
  onboarding_schoolStructure,
  onboarding_schoolStructure_last,
  onboarding_Structure,
  onboarding_Structure_last,
  formInstructions,
  formInstructions_last,
  onboarding_agreements,
  onboarding_agreements_last,
  onboarding_changelog,
  onboarding_changelog_last,
  onboarding_email,
  onboarding_email_last,
  onboarding_system,
  onboarding_system_last,
  onboarding_broadcast,
  onboarding_broadcast_last,
  onboarding_classes,
  onboarding_classes_last,
  onboarding_class_manage,
  onboarding_class_manage_last,
  onboarding_ticket,
  onboarding_ticket_last,
  onboarding_faq,
  onboarding_faq_last,
  onboarding_setting,
  onboarding_setting_last,
};
