import { useContext, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import { IoIosClose } from "react-icons/io";

const NotificationList = () => {
  const { broadcastMessage, removeBroadcastMessage } =
    useContext(GlobalContext);

  const handleRemoveNotification = (id) => {
    removeBroadcastMessage(id);
  };

  return (
    <div
      className="bg-gray-200 shadow-xl cursor-pointer dark:bg-gray-600 dark:text-white py-2 px-3 
      border-solid border-gray-400 border-[2px] absolute top-14 md:right-24 right-16 rounded-md 
    md:w-[600px] w-[80%]  h-[400px] z-[9999]"
    >
      <div className="h-full overflow-y-auto">
        <h2 className="text-[18px] font-bold w-full border-b py-3">
          Notifications
        </h2>
        <div className="dark:bg-gray-700 bg-gray-100 shadow-md py-2 px-3 border rounded-lg h-[80%] w-full mt-5 overflow-y-auto">
          <table className="table w-[100%] md:w-full ">
            <thead>
              <tr>
                <th className="border-b p-2 text-sm "></th>
                <th className="border-b p-2 text-left text-sm ">Title</th>
                <th className="border-b p-2 text-sm ">Message</th>
                <th className="border-b p-2 text-sm ">Timestamp</th>
                <th className="border-b p-2 text-sm ">Type</th>
                <th className="border-b p-2 text-sm "></th>
              </tr>
            </thead>
            <tbody>
              {broadcastMessage?.map((item, index) =>
                item.notifications?.map((notification) => (
                  <tr key={notification.id} className="relative">
                    <td className="border-b text-sm text-left">
                      <span className="inline-block w-2 h-2 bg-red-500 rounded-full"></span>
                    </td>
                    <td className="border-b p-2 text-sm text-left">
                      {notification.title}
                    </td>
                    <td className="border-b p-2 text-sm text-center">
                      {notification.message}
                    </td>
                    <td className="border-b p-2 text-sm text-center">
                      {notification.timestamp}
                    </td>
                    <td className="border-b p-2 text-sm text-center">
                      {notification.type}
                    </td>
                    <td className="border-b p-2 text-sm text-center">
                      <IoIosClose
                        size={20}
                        className="cursor-pointer"
                        onClick={() =>
                          handleRemoveNotification(notification.id)
                        }
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NotificationList;
