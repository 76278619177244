import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { useFormik } from "formik";
import { GrFormView } from "react-icons/gr";
import TicketModel from "../../components/ticket-model";
import { MdEdit } from "react-icons/md";
import TicketEditModel from "../../components/ticket-edit-model";
import useTicket from "../../hooks/useTicket";
import useDeleteTicket from "../../hooks/useDeleteTicket";
import { ButtonLoader2 } from "../../components/buttonLoader/ButtonLoader";
import axios from "axios";
import { FaChevronLeft, FaChevronRight, FaRegFileAlt } from "react-icons/fa";
import {
  FcHighPriority,
  FcLowPriority,
  FcMediumPriority,
} from "react-icons/fc";
import { IoBanOutline } from "react-icons/io5";
import { BiError } from "react-icons/bi";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { DatePicker } from "antd";
import FileViewModal from "../../components/fileView";
import OnBoardingInstruction from "../../components/onBoardingInstruction";
import rawData from "../../components/rawData";
import { LiaComments } from "react-icons/lia";
import CommentsModal from "../../components/ticketsComments";
import GlobalContext from "../../context/GlobalContext";

const { onboarding_ticket, onboarding_ticket_last } = rawData;
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const formSchema = Yup.object({
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
  priority: Yup.string().required("Priority selection is required"),
  comments: Yup.string(),
  file: Yup.array().of(Yup.mixed()).nullable(),
});

const initialValues = {
  subject: "",
  message: "",
  priority: "",
  comments: "",
  file: [],
};
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const priorities = ["Low", "Medium", "High"];
const TicketSystem = () => {
  const { loading, userTickets } = useTicket();
  const [subscribed, setSubscribed] = useState(true);
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [showEditModel, setShowEditModel] = useState(false);
  const [ticketDetail, setTicketDetail] = useState(null);
  const [user, setUser] = useState("admin");
  const [tableData, setTableData] = useState([]);
  const [updatedTickets, setUpdatedTickets] = useState(false);
  const [tableLoad, setTableLoad] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState(priorities[0]);
  const { userInfo } = useContext(GlobalContext);

  const getTickets = async () => {
    setTableLoad(true);
    await axios
      .get(`${BASE_URL}/school/ticket/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setTableData(response.data);
        setTableLoad(false);
        setUpdatedTickets(false);
      })
      .catch((error) => {
        setTableLoad(false);
      });
  };
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 25;
  const paginationRef = useRef(null);
  const totalPages = Math.ceil(tableData.length / rowsPerPage);
  const currentData = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const scrollToActiveButton = (index) => {
    if (paginationRef.current) {
      const activeButton = paginationRef.current.children[index];
      if (activeButton) {
        activeButton.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  useEffect(() => {
    scrollToActiveButton(currentPage - 1);
  }, [currentPage]);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  useEffect(() => {
    getTickets();
  }, [updatedTickets]);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("title", values.subject);
      formData.append("description", values.message);
      formData.append("priority", values.priority);
      formData.append("comments", values.comments);

      formData.append("status", "Open");
      // Only append files if there are any
      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append("files", file);
        });
      }
      formData.append("user_id", sessionStorage.getItem("user_id"));

      try {
        await userTickets(formData);
        formik.resetForm();
        setFiles(null);
        setUpdatedTickets(true);
      } catch (error) {
        setUpdatedTickets(false);
      }
    },
  });

  const isSubmitDisabled = !formik.isValid || formik.isSubmitting;

  const handleCancel = (e) => {
    e.preventDefault();
    formik.resetForm();
    setFiles(null);
  };
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "image/png": [],
        "image/jpeg": [],
        "image/jpg": [],
        "application/pdf": [],
      },
      multiple: false,
      onDrop: (acceptedFiles) => {
        setFiles(acceptedFiles);
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("token");

  return subscribed ? (
    <main>
      <OnBoardingInstruction
        onboarding_instruction={onboarding_ticket}
        onboarding_instruction_last={onboarding_ticket_last}
      />
      {show && <TicketModel setShow={setShow} rowData={rowData} />}
      <div className="flex justify-between items-center gap-3">
        <h2 className="text-xl font-bold dark:text-white">Ticketsystem</h2>
      </div>
      <div className="mt-6 mb-4 md:p-8 p-3 bg-white dark:bg-gray-700 rounded-[8px]">
        {userInfo?.role === "School" && (
          <form
            className="w-full py-5 gap-5 flex flex-col"
            onSubmit={formik.handleSubmit}
          >
            <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
              <p className="text-[12px] lg:text-[16px] py-3 text-left w-[200px] px-3">
                Thema
              </p>
              <input
                type="text"
                className="input text-[12px] lg:text-[16px] outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300  w-full rounded-[6px]"
                name="subject"
                placeholder="geben sie den betreff ein..."
                value={formik.values.subject}
                onChange={formik.handleChange}
              />
            </div>
            {formik.errors.subject && formik.touched.subject && (
              <div className="w-full flex items-end justify-start">
                <p className="dark:text-slate-100 text-red-700 dark:font-bold font-medium mt-1 text-xs flex items-center gap-1 justify-start">
                  <BiError className="text-red-700 text-[18px]" />
                  {formik.errors.subject}
                </p>
              </div>
            )}
            <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
              <p className=" py-3 text-left w-[200px] px-3 text-[12px] lg:text-[16px]">
                Beschreibung
              </p>
              <textarea
                type="text"
                className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px] resize-none text-[12px] lg:text-[16px] dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 "
                name="message"
                placeholder="geben sie die beschreibung ein..."
                value={formik.values.message}
                onChange={formik.handleChange}
              />
            </div>
            {formik.errors.message && formik.touched.message && (
              <div className="w-full flex items-end justify-start">
                <p className="dark:text-slate-100 text-red-700 dark:font-bold font-medium mt-1 text-xs flex items-center gap-1 justify-start">
                  <BiError className="text-red-700 text-[18px]" />
                  {formik.errors.message}
                </p>
              </div>
            )}

            <div className="select dark:select-dark rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
              <p className="py-3 text-left w-[200px] px-3 text-[12px] lg:text-[16px]">
                Priorität
              </p>
              <select
                name="priority"
                value={formik.values.priority}
                onChange={(e) =>
                  formik.setFieldValue("priority", e.target.value)
                }
                className="block h-[45px] px-4 w-full text-sm text-gray-900 rounded-md bg-[#e5e7eb] dark:bg-gray-500 dark:text-white appearance-none"
              >
                <option value="" disabled>
                  Wählen Sie Priorität
                </option>
                <option value="Low">Niedrig</option>
                <option value="Medium">Medium</option>
                <option value="High">Hoch</option>
              </select>
            </div>

            {formik.errors.priority && formik.touched.priority && (
              <div className="w-full flex items-end justify-start">
                <p className="dark:text-slate-100 text-red-700 dark:font-bold font-medium mt-1 text-xs flex items-center gap-1 justify-start">
                  <BiError className="text-red-700 text-[18px]" />
                  {formik.errors.priority}
                </p>
              </div>
            )}
            <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
              <p className=" py-3 text-left w-[200px] px-3 text-[12px] lg:text-[16px]">
                Kommentare
              </p>
              <textarea
                type="text"
                className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px] resize-none text-[12px] lg:text-[16px] dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 "
                name="comments"
                placeholder="fügen sie einige kommentare hinzu..."
                value={formik.values.comments}
                onChange={formik.handleChange}
              />
            </div>
            <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
              <p className="text-[12px] lg:text-[16px] py-3 text-left w-[200px] px-3">
                Select Files
              </p>
              <div className="container bg-gray-200 dark:bg-gray-500 dark:text-white text-gray-400">
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <div className="flex flex-col items-center justify-center mt-3">
                    <p className="lg:text-xl text-[12px]">
                      Ziehen Sie einige PNG-, JPG-, JPEG- und PDF-Dateien per
                      Drag & Drop
                    </p>
                    <p className="lg:text-xl text-[12px]">or</p>
                    <button
                      type="button"
                      className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
                    >
                      Wählen
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="-mt-1 w-full flex flex-col items-end justify-end">
              {files?.map((file, index) => (
                <div
                  key={index}
                  className="text-gray-700 text-sm dark:text-white"
                >
                  {file.name}
                </div>
              ))}
              <div className="flex items-center justify-center gap-4 mt-2">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300 text-[12px] lg:text-[16px]"
                >
                  Stornieren
                </button>
                <button
                  type="submit"
                  className={`py-2 px-4 rounded-md transition duration-300 text-[12px] h-[40px] w-[120px] flex items-center justify-center lg:text-[16px] ${
                    !isSubmitDisabled
                      ? "bg-indigo-600 text-white hover:bg-indigo-700"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed"
                  }`}
                  disabled={isSubmitDisabled}
                >
                  {loading ? <ButtonLoader2 /> : "Einreichen"}
                </button>
              </div>
            </div>
          </form>
        )}

        <h2 className="text-xl font-bold dark:text-white">
          Ticket eingereicht
        </h2>

        <TicketsystemTable
          setShow={setShow}
          rowData={rowData}
          setRowData={setRowData}
          tableData={tableData}
          setShowEditModel={setShowEditModel}
          setTicketDetail={setTicketDetail}
          userInfo={userInfo}
          loading={tableLoad}
          getTickets={getTickets}
          currentData={currentData}
          handlePrevPage={handlePrevPage}
          paginationRef={paginationRef}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          handleNextPage={handleNextPage}
          currentPage={currentPage}
        />
        {showEditModel && (
          <TicketEditModel
            ticketDetail={ticketDetail}
            setTicketDetail={setTicketDetail}
            setTableData={setTableData}
            setShowEditModel={setShowEditModel}
            setUpdatedTickets={setUpdatedTickets}
            getTickets={getTickets}
            files={files}
            setFiles={setFiles}
          />
        )}
      </div>
    </main>
  ) : (
    <div className="flex items-center justify-center h-[75vh] flex-col pt-5">
      <h2 className="text-xl font-semibold dark:text-white">
        You have not subscribed yet!
      </h2>
      <h2 className="text-xl font-semibold dark:text-white">
        Subscribe first to view ticketsystem
      </h2>
      <button className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300">
        Subscribe
      </button>
    </div>
  );
};

export default TicketSystem;

const TicketsystemTable = ({
  setShow,
  setRowData,
  tableData,
  setShowEditModel,
  setTicketDetail,
  userInfo,
  loading,
  getTickets,
  handlePrevPage,
  paginationRef,
  totalPages,
  setCurrentPage,
  handleNextPage,
  currentPage,
  rowData,
}) => {
  const [filteredData, setFilteredData] = useState(tableData);
  const [selectedPriority, setSelectedPriority] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [showFile, setShowFile] = useState(false);
  const [showComments, setShowComments] = useState(false);

  const [pdfFile, setPdfFile] = useState(null);
  const { RangePicker } = DatePicker;
  // Handle date range change
  const handleDateRangeChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setDateRange([start ? dayjs(start) : null, end ? dayjs(end) : null]);
    } else {
      setDateRange([]);
    }
  };

  // Filter data based on priority, status, and date range
  useEffect(() => {
    let filtered = tableData;
    // Filter by priority
    if (selectedPriority) {
      filtered = filtered.filter((item) => item.priority === selectedPriority);
    }
    // Filter by status
    if (selectedStatus) {
      filtered = filtered.filter((item) => item.status === selectedStatus);
    }
    // Filter by date range
    if (dateRange.length === 2) {
      const [startDate, endDate] = dateRange;
      filtered = filtered.filter((item) => {
        const itemDate = dayjs(item.submited_at);
        return (
          itemDate.isSameOrAfter(startDate, "day") &&
          itemDate.isSameOrBefore(endDate, "day")
        );
      });
    }

    setFilteredData(filtered);
  }, [selectedPriority, selectedStatus, dateRange, tableData]);

  const handleRowClick = (rowData) => {
    setRowData(rowData);
    setShow(true);
  };
  const handleCommentModal = (rowData) => {
    setRowData(rowData);
    setShowComments(true);
  };
  const handleModel = (item) => {
    setShowEditModel(true);
    setTicketDetail(item);
  };

  const { DeletingTicket } = useDeleteTicket();

  const handleDelete = (id) => {
    DeletingTicket(id);
    getTickets();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Function to check if the file is a PDF
  const isPdfFile = (fileName) => {
    return fileName?.toLowerCase().endsWith(".pdf");
  };

  const handleFileClick = (file) => {
    if (isPdfFile(file)) {
      setPdfFile(`https://service.klassenbote.de/${file}`);
      setShowFile(true);
    } else {
      // Handle non-PDF files, if necessary
      window.open(`https://service.klassenbote.de/${file}`, "_blank");
    }
  };
  return (
    <div className="overflow-x-auto dark:text-white">
      {/* Filter Section */}
      {showFile && <FileViewModal setShow={setShowFile} file={pdfFile} />}
      {showComments && (
        <CommentsModal setShow={setShowComments} rowData={rowData} />
      )}

      <div className="flex gap-4 mb-4 w-full items-center justify-end">
        <div>
          <label htmlFor="priority">Priority:</label>
          <select
            id="priority"
            className="ml-2 border rounded px-2 py-1 dark:bg-gray-600"
            value={selectedPriority}
            onChange={(e) => setSelectedPriority(e.target.value)}
          >
            <option value="">All</option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
          </select>
        </div>
        <div>
          <label htmlFor="status">Status:</label>
          <select
            id="status"
            className="ml-2 border rounded px-2 py-1 dark:bg-gray-600"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="">All</option>
            <option value="Open">Open</option>
            <option value="In Progress">In Progress</option>
            <option value="Closed">Closed</option>
          </select>
        </div>
        <RangePicker className="w-[200px]" onChange={handleDateRangeChange} />
      </div>

      {loading ? (
        <div className="w-full flex items-center justify-center">
          <ButtonLoader2 />
        </div>
      ) : (
        <table className="table mt-10 md:w-full w-[180%] mb-[200px]">
          <thead>
            <tr>
              <th className="border-b text-sm p-3 text-left">Ticket ID</th>
              <th className="border-b text-sm p-3 text-left">Subject</th>
              <th className="border-b text-sm p-3 text-left">Status</th>
              <th className="border-b text-sm p-3 text-center">Priority</th>
              <th className="border-b text-sm p-4 text-center">
                Date Submitted
              </th>
              <th className="border-b text-sm p-4 text-center">Datei</th>
              <th className="border-b text-sm p-3 text-left"></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index} className="capitalize">
                  <td className="border-b text-sm p-3 text-left">{item.id}</td>
                  <td className="border-b text-sm p-3 text-left">
                    {item.title}
                  </td>
                  <td className="border-b text-sm p-3 text-left">
                    <div className="flex text-sm justify-start items-center gap-2">
                      <span>{item.status}</span>
                    </div>
                  </td>
                  <td className="border-b text-sm p-3 text-center">
                    <div className="flex items-center justify-center">
                      {item.priority === "High" && <FcHighPriority size={20} />}
                      {item.priority === "Low" && <FcLowPriority size={20} />}
                      {item.priority === "Medium" && (
                        <FcMediumPriority size={20} />
                      )}
                    </div>
                  </td>
                  <td className="border-b text-sm p-4 text-center">
                    {formatDate(item.submited_at)}
                  </td>
                  <td className="border-b text-sm p-4 text-center">
                    {item.files === null ? (
                      <div className="flex items-center justify-center">
                        <IoBanOutline color="red" />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        {/* <a
                          href={`https://service.klassenbote.de/${item.files}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        > */}
                        <FaRegFileAlt
                          className="cursor-pointer"
                          onClick={() => handleFileClick(item.files)}
                        />
                        {/* </a> */}
                      </div>
                    )}
                  </td>

                  <td className="border-b text-sm p-4 flex items-center justify-end ">
                    <div className="flex items-center gap-3">
                      <LiaComments
                        className=" text-gray-600 dark:text-white hover:text-indigo-700 w-[16px] h-[16px] cursor-pointer"
                        onClick={() => handleCommentModal(item)}
                      />

                      <GrFormView
                        className=" text-gray-600 dark:text-white hover:text-indigo-700 w-[22px] h-[22px] cursor-pointer"
                        onClick={() => handleRowClick(item)}
                      />
                      {userInfo?.role === "Admin" && (
                        <div className="flex items-center gap-3">
                          <MdEdit
                            className="text-gray-600 dark:text-white hover:text-indigo-700  w-[20px] h-[20px] cursor-pointer"
                            onClick={() => handleModel(item)}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="5"
                  className="text-center text-gray-500 dark:text-white py-4"
                >
                  Keine Datensätze gefunden
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {/* Pagination */}
      {filteredData.length > 0 && (
        <div className="pagination mt-5 flex justify-center items-start gap-2 w-full overflow-hidden">
          <button
            className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <div
            className={`w-[50%] flex overflow-auto ${
              currentPage === 1 ? "justify-center" : "justify-start"
            }  items-start gap-2`}
            ref={paginationRef}
          >
            {[...Array(totalPages).keys()].map((page) => (
              <button
                key={page}
                className={`btn ${
                  currentPage === page + 1
                    ? "bg-blue-500 text-white"
                    : "text-gray-400"
                } px-4 py-1 rounded text-sm flex justify-center items-center gap-3`}
                onClick={() => setCurrentPage(page + 1)}
              >
                {page + 1}
              </button>
            ))}
          </div>
          <button
            className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
    </div>
  );
};
