import React, { useState } from "react";

const NotificationPreferences = () => {
  const [emailReminders, setEmailReminders] = useState(true);
  const [reminderFrequency, setReminderFrequency] = useState("weekly");

  const handleSavePreferences = () => {
    // Save preferences logic here
    alert("Notification preferences saved!");
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full mx-auto">
      <h2 className="text-xl font-semibold mb-4 dark:text-white">
        Benachrichtigungspräferenzen{" "}
      </h2>

      <div className="mb-4">
        <label className="block text-lg font-semibold dark:text-gray-200 mb-2">
          E-Mail-Erinnerungen
        </label>
        <input
          type="checkbox"
          checked={emailReminders}
          onChange={(e) => setEmailReminders(e.target.checked)}
          className="w-5 h-5"
        />
      </div>

      <div className="mb-4">
        <label className="block text-lg font-semibold dark:text-gray-200 mb-2">
          Erinnerungshäufigkeit
        </label>
        <select
          value={reminderFrequency}
          onChange={(e) => setReminderFrequency(e.target.value)}
          className="w-full p-2 border rounded-md dark:bg-gray-600 outline-none"
        >
          <option value="daily">Täglich</option>
          <option value="weekly">Wöchentlich</option>
          <option value="monthly">Monatlich</option>
        </select>
      </div>

      <button
        onClick={handleSavePreferences}
        className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
      >
        Präferenzen speichern
      </button>
    </div>
  );
};

export default NotificationPreferences;
