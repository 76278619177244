import React, { useState } from "react";
import FileViewModal from "../fileView";
import { ButtonLoader2 } from "../buttonLoader/ButtonLoader";
import { IoBanOutline } from "react-icons/io5";
import { FaChevronLeft, FaChevronRight, FaRegFileAlt } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { DatePicker } from "antd";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const Table = ({
  setShow,
  setRowData,
  setShowEditModel,
  setTicketDetail,
  user,
  loading,
  handlePrevPage,
  paginationRef,
  totalPages,
  setCurrentPage,
  handleNextPage,
  currentPage,
  filteredData,
  setDateRange,
}) => {
  const [showFile, setShowFile] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const { RangePicker } = DatePicker;
  const handleDateRangeChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setDateRange([start ? dayjs(start) : null, end ? dayjs(end) : null]);
    } else {
      setDateRange([]);
    }
  };
  const handleRowClick = (rowData) => {
    setRowData(rowData);
    setShow(true);
  };

  const handleModel = (item) => {
    setShowEditModel(true);
    setTicketDetail(item);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Function to check if the file is a PDF
  const isPdfFile = (fileName) => {
    return fileName?.toLowerCase().endsWith(".pdf");
  };

  const handleFileClick = (file) => {
    if (isPdfFile(file)) {
      setPdfFile(`https://service.klassenbote.de/${file}`);
      setShowFile(true);
    } else {
      // Handle non-PDF files, if necessary
      window.open(`https://service.klassenbote.de/${file}`, "_blank");
    }
  };
  return (
    <div className="overflow-x-auto dark:text-white">
      {/* Filter Section */}
      {showFile && <FileViewModal setShow={setShowFile} file={pdfFile} />}
      <div className="flex gap-4 mb-4 w-full items-center justify-end">
        <RangePicker className="w-[200px]" onChange={handleDateRangeChange} />
      </div>

      {loading ? (
        <div className="w-full flex items-center justify-center">
          <ButtonLoader2 />
        </div>
      ) : (
        <table className="table mt-10 md:w-full w-[180%] mb-[200px]">
          <thead>
            <tr>
              <th className="border-b text-sm p-3 text-left">ID </th>
              <th className="border-b text-sm p-3 text-left">Document Title</th>
              <th className="border-b text-sm p-4 text-center">File</th>
              <th className="border-b text-sm p-4 text-center">
                Date Submitted
              </th>
              <th className="border-b text-sm p-3 text-left"></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index} className="capitalize">
                  <td className="border-b text-sm p-3 text-left">{item.id}</td>
                  <td className="border-b text-sm p-3 text-left">
                    {item.document_title}
                  </td>
                  <td className="border-b text-sm p-4 text-center">
                    {item.file === null ? (
                      <div className="flex items-center justify-center">
                        <IoBanOutline color="red" />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        {/* <a
                          href={`https://service.klassenbote.de/${item.files}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        > */}
                        <FaRegFileAlt
                          className="cursor-pointer"
                          onClick={() => handleFileClick(item.file)}
                        />
                        {/* </a> */}
                      </div>
                    )}
                  </td>
                  <td className="border-b text-sm p-4 text-center">
                    {formatDate(item.created_at)}
                  </td>
                  <td className="border-b text-sm p-4 flex items-center justify-end ">
                    <div className="flex items-center gap-3">
                      {user === "admin" && (
                        <div className="flex items-center gap-3">
                          <MdEdit
                            className="text-gray-600 dark:text-white hover:text-indigo-700  w-[20px] h-[20px] cursor-pointer"
                            onClick={() => handleModel(item)}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="5"
                  className="text-center text-gray-500 dark:text-white py-4"
                >
                  Keine Datensätze gefunden
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {/* Pagination */}
      {filteredData.length > 0 && (
        <div className="pagination mt-5 flex justify-center items-start gap-2 w-full overflow-hidden">
          <button
            className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <div
            className={`w-[50%] flex overflow-auto ${
              currentPage === 1 ? "justify-center" : "justify-start"
            }  items-start gap-2`}
            ref={paginationRef}
          >
            {[...Array(totalPages).keys()].map((page) => (
              <button
                key={page}
                className={`btn ${
                  currentPage === page + 1
                    ? "bg-blue-500 text-white"
                    : "text-gray-400"
                } px-4 py-1 rounded text-sm flex justify-center items-center gap-3`}
                onClick={() => setCurrentPage(page + 1)}
              >
                {page + 1}
              </button>
            ))}
          </div>
          <button
            className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default Table;
