import React, { useContext, useEffect, useRef, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { RiAdminLine, RiUser3Line } from "react-icons/ri";
import useTicketChatSocket from "../../hooks/useTicketChatSocket";
import ReconnectingWebSocket from "reconnecting-websocket";
import GlobalContext from "../../context/GlobalContext";

const CommentsModal = ({ setShow, rowData }) => {
  const [newComment, setNewComment] = useState("");
  const { ConnectWebSockets, SubmitMessage, message } = useTicketChatSocket();
  const { chatRef, userInfo } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_SOCKET_URL;
  const token = sessionStorage.getItem("token");
  const messagesEndRef = useRef(null);
  const addComment = () => {
    SubmitMessage({
      message: newComment,
      // receiver: message[0].super_user,
      // receiver:
      //   userInfo?.role === "Admin" || userInfo.role === "super-admin" ? 2 : 1,
    });
    setNewComment("");
  };

  useEffect(() => {
    chatRef.current = new ReconnectingWebSocket(
      `${BASE_URL}/ws/ticket/chat/${rowData.id}/?token=${token}`
    );
    ConnectWebSockets();
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message]);

  return (
    <div className="w-screen h-screen bg-[#8383838c] flex items-center justify-center fixed top-0 left-0 z-50">
      <div className="w-[90%] md:w-[60%] py-5 px-5 bg-white dark:bg-gray-800 rounded-xl dark:text-white relative">
        <IoIosCloseCircle
          className="absolute -right-3 -top-2 text-2xl bg-white dark:bg-gray-700 rounded-full cursor-pointer"
          onClick={() => setShow(false)}
        />
        <h2 className="text-xl font-bold py-4">Comments</h2>
        <div className="bg-gray-700 w-full p-4 rounded-2xl">
          <h2 className="text-lg font-medium pb-4 underline">Ticket Details</h2>
          <p className="text-md">Ticket Title: {rowData.title}</p>
          <p className="text-md">Ticket ID: {rowData.id}</p>
        </div>
        <div className="w-full flex items-start justify-start h-[60vh] relative">
          <div className="p-4 w-full h-[50vh] flex flex-col gap-5 overflow-scroll">
            {message?.map((comment, index) => {
              const isAdminMessage =
                comment?.sender_role === "Admin" ||
                comment?.sender_role === "is_superuser" ||
                comment?.sender_role === "is_staff";

              const isCurrentUserAdmin =
                userInfo.role === "Admin" ||
                userInfo.role === "is_superuser" ||
                userInfo.role === "is_staff";

              const alignClass = isAdminMessage
                ? isCurrentUserAdmin
                  ? "flex-row-reverse"
                  : ""
                : isCurrentUserAdmin
                ? ""
                : "flex-row-reverse";

              return (
                <div
                  key={index}
                  className={`flex items-start gap-2.5 w-full ${alignClass}`}
                >
                  {/* Display appropriate icon based on the sender */}
                  {isAdminMessage ? (
                    <RiAdminLine className="w-5 h-5 rounded-full" />
                  ) : (
                    <RiUser3Line className="w-5 h-5 rounded-full" />
                  )}
                  <div className="flex flex-col gap-1 w-full max-w-[320px]">
                    {/* Display role name */}
                    <span className="text-sm font-semibold text-gray-900 dark:text-white">
                      {isAdminMessage ? "Admin" : "User"}
                    </span>
                    {/* Message content with conditional styling */}
                    <div
                      className={`flex flex-col leading-1.5 p-4 border-gray-200 bg-gray-100 ${
                        alignClass
                          ? "rounded-tl-xl rounded-bl-xl rounded-br-xl"
                          : "rounded-tr-xl rounded-br-xl rounded-bl-xl"
                      } dark:bg-gray-700`}
                    >
                      <p className="text-sm font-normal text-gray-900 dark:text-white">
                        {comment?.message}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
            <div ref={messagesEndRef}></div>
          </div>
          <div className="w-full flex items-center justify-center absolute bottom-2">
            <input
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && addComment()}
              placeholder="Add a comment..."
              className="w-[95%] p-4 dark:bg-gray-700 bg-gray-300 rounded-2xl border outline-none shadow-md dark:shadow-gray-500 shadow-gray-400"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsModal;
