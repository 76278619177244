import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { MdDelete, MdEdit } from "react-icons/md";
import QuestionEditModel from "../../components/question-edit-model";
import useQuestionDelete from "../../hooks/useQuestionDelete";
import rawData from "../../components/rawData";
import OnBoardingInstruction from "../../components/onBoardingInstruction";
const { onboarding_faq, onboarding_faq_last } = rawData;
const Questions = () => {
  const [activeTab, setActiveTab] = useState("General");
  const [user, setUser] = useState("admin");
  const [showEditModel, setShowEditModel] = useState(false);
  const [questionDetail, setQuestionDetail] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openedQuestion, setOpenedQuestion] = useState(false);
  const token = sessionStorage.getItem("token");
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/faq/?category=${activeTab}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [activeTab]);

  const handleQuestionClick = (id) => {
    setOpenedQuestion(openedQuestion === id ? null : id);
  };

  const handleModel = (item) => {
    setShowEditModel(true);
    setQuestionDetail(item);
  };

  const handleUpdateQuestion = (updatedQuestion) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === updatedQuestion.id ? updatedQuestion : q
      )
    );
    setShowEditModel(false);
  };

  const { DeletingQuestion } = useQuestionDelete();
  const handleDelete = (id) => {
    DeletingQuestion(id);
    fetchQuestions();
  };

  const filteredQuestions = questions.filter((item) =>
    item.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderContent = () => {
    if (loading) {
      return <p>Loading...</p>;
    }

    switch (activeTab) {
      case "General":
      case "Billing":
      case "Technical Support":
        return (
          <div className="flex flex-col items-start w-full">
            <div className="input-holder w-full rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center">
              <p className="text-[12px] py-3 text-left lg:w-[200px] w-[100px] px-3">
                Search
              </p>
              <input
                type="text"
                className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 dark:text-white w-full rounded-[6px] text-[12px] lg:text-[16px]"
                placeholder="Search for a specific question..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start w-full mt-6 gap-4">
              {filteredQuestions.length > 0 ? (
                filteredQuestions.map((item) => (
                  <div
                    className="flex flex-col items-start w-full"
                    key={item.id}
                  >
                    {item.question && (
                      <div className="flex items-center gap-4 w-full cursor-pointer relative">
                        {openedQuestion === item.id ? (
                          <FaMinus
                            className="text-gray-800 dark:text-white w-[20px] h-[20px]"
                            onClick={() => handleQuestionClick(item.id)}
                          />
                        ) : (
                          <FaPlus
                            className="text-gray-800 dark:text-white w-[20px] h-[20px]"
                            onClick={() => handleQuestionClick(item.id)}
                          />
                        )}

                        <div className="bg-gray-200 dark:bg-gray-500 w-[100%] py-3 px-3">
                          <p className=" text-[11px] w-[90%] lg:text-[16px] dark:text-white">
                            {item.question}
                          </p>
                        </div>
                        {user === "admin" && (
                          <div className="flex items-center justify-center lg:-mt-0 -mt-1 gap-1 absolute top-4 right-3">
                            <MdEdit
                              onClick={() => handleModel(item)}
                              className="text-gray-600 hover:text-indigo-700 dark:text-white  w-[15px] h-[15px] cursor-pointer"
                            />
                            <MdDelete
                              className="text-gray-600 hover:text-red-700 dark:text-white w-[15px] h-[15px] cursor-pointer"
                              onClick={() => handleDelete(item.id)}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <div
                      className={`overflow-hidden flex items-start transition-all pl-[35px] duration-300 w-full ease-in-out  ${
                        openedQuestion === item.id ? "max-h-[500px]" : "max-h-0"
                      }`}
                    >
                      <p className="bg-gray-100 w-full py-3 px-3 text-[12px] lg:text-[16px] dark:bg-gray-600">
                        {item.answer}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p>Keine Fragen gefunden</p>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <main>
      <OnBoardingInstruction
        onboarding_instruction={onboarding_faq}
        onboarding_instruction_last={onboarding_faq_last}
      />
      {showEditModel && (
        <QuestionEditModel
          setShowEditModel={setShowEditModel}
          questionDetail={questionDetail}
          handleUpdateQuestion={handleUpdateQuestion}
          fetchQuestions={fetchQuestions}
        />
      )}
      <div className="flex justify-between items-center gap-3">
        <h2 className="text-xl font-bold dark:text-white">
          Häufig gestellte Fragen
        </h2>
      </div>

      <div className="card mt-6 mb-4 bg-white dark:bg-gray-700 dark:text-white md:p-8 p-3 rounded-[8px]">
        <div className="p-4">
          <div className="flex justify-around w-full">
            {["General", "Billing", "Technical Support"].map((category) => (
              <div
                key={category}
                onClick={() => setActiveTab(category)}
                className={`cursor-pointer p-2 px-4 text-sm font-semibold w-[33%] text-center flex items-center justify-center lg:text-[16px] text-[12px]  ${
                  activeTab === category
                    ? "text-indigo-700 bg-gray-200 dark:bg-gray-600 dark:text-white"
                    : "text-gray-400 bg-gray-100 hover:bg-slate-100 hover:text-indigo-500 dark:bg-gray-500 dark:hover:text-gray-400"
                }`}
              >
                {category}
              </div>
            ))}
          </div>
          <div className="mt-16">{renderContent()}</div>
        </div>
      </div>
    </main>
  );
};

export default Questions;
