import React, { useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import GlobalContext from "../../context/GlobalContext";
const DualGraph = ({ view, data, title, title_2 }) => {
  const { dark } = useContext(GlobalContext);

  return (
    <div>
      {view === "monthly" && (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" stroke={dark ? "#fff" : "#000"} />
            <YAxis stroke={dark ? "#fff" : "#000"} />
            <Tooltip />
            <Legend />
            <Bar dataKey={title} fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      )}

      {view === "total" && (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" stroke={dark ? "#fff" : "#000"} />
            <YAxis stroke={dark ? "#fff" : "#000"} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={title_2} stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default DualGraph;
