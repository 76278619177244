import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import useNewsletter from "../../hooks/useNewsletter";
import { ButtonLoader1 } from "../../components/buttonLoader/ButtonLoader";

const Footer = () => {
  const [emailValue, setEmailValue] = useState(null);
  const navigation = useNavigate();
  const location = useLocation();
  const { SubmitNewsletter, loading } = useNewsletter();

  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="max-w-6xl mx-auto px-4 flex flex-wrap justify-between items-center flex-col md:flex-row">
        <div className="mb-2 md:mb-0">
          <a
            href="/"
            className="text-2xl font-bold text-indigo-500 hover:text-indigo-600"
          >
            Klassenbote
          </a>
        </div>
        <ul className="flex flex-wrap items-center mb-6 md:mb-0"></ul>
        <div className="flex items-center">
          <a
            href="https://facebook.com"
            className="text-white mr-6 hover:text-gray-400"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://twitter.com"
            className="text-white mr-6 hover:text-gray-400"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            href="https://instagram.com"
            className="text-white hover:text-gray-400"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
      {location.pathname === "/" && (
        <Newsletter
          navigation={navigation}
          SubmitNewsletter={SubmitNewsletter}
          loading={loading}
          emailValue={emailValue}
          setEmailValue={setEmailValue}
        />
      )}
      <div className="text-center text-gray-500 text-sm mt-4">
        © {new Date().getFullYear()} Klassenbote. Alle Rechte vorbehalten.
      </div>
    </footer>
  );
};

export default Footer;
const Newsletter = ({
  navigation,
  SubmitNewsletter,
  loading,
  emailValue,
  setEmailValue,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    SubmitNewsletter({
      email: emailValue,
    });
  };
  return (
    <section className="w-full">
      <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">
        <div className="mx-auto max-w-screen-md sm:text-center">
          <h2 className="mb-4 text-2xl tracking-tight font-extrabold  sm:text-2xl text-white">
            Für unseren Newsletter anmelden
          </h2>
          <p className="mx-auto mb-4 max-w-2xl font-light text-gray-500 md:mb-3 text-md text-[16px] dark:text-gray-400">
            Bleiben Sie auf dem Laufenden über den Fortschritt der Roadmap,
            Ankündigungen und exklusive Rabatte. Melden Sie sich gerne mit Ihrer
            E-Mail-Adresse an.
          </p>
          <form action="#">
            <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
              <div className="relative w-full">
                <label
                  for="email"
                  className="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  E-Mail-Adresse{" "}
                </label>
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                  </svg>
                </div>
                <input
                  className="block p-3 outline-none
                       pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Geben Sie Ihre E-Mail-Adresse ein..."
                  type="email"
                  id="email"
                  required=""
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="
                  py-3 px-5 
                  h-[46px]
                  w-full text-sm font-medium text-center
                   text-white rounded-xl bg-indigo-500 overflow-hidden
                    cursor-pointer bg-primary-700  sm:rounded-none
                  sm:rounded-r-lg 
                  hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 
                  dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  onClick={handleSubmit}
                >
                  {loading ? <ButtonLoader1 /> : "Abonnieren"}
                </button>
              </div>
            </div>
            <div className="mx-auto max-w-screen-sm text-sm text-center text-gray-500 newsletter-form-footer dark:text-gray-300">
              Wir kümmern uns um den Schutz Ihrer Daten.{" "}
              <a
                onClick={() => navigation("/terms-and-conditions")}
                className="font-medium text-primary-600 dark:text-primary-500 hover:underline cursor-pointer"
              >
                Lesen Sie unsere Datenschutzrichtlinie.{" "}
              </a>
              .
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
