import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import useGetProfile from "./useGetProfile";
// import useGetUserInfo from "./useGetUserInfo";

function useSignin() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const Navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const Signin = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/users/login/`, payload)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: response.data.message,
          customClass: {
            confirmButton: "custom-green-button",
          },
        });
        if (response.data.access) {
          // Navigate(`/verify-otp/${payload.email}/2fa`);
          sessionStorage.setItem("token", response.data.access);
          sessionStorage.setItem("refresh_token", response.data.refresh);

          window.location.href = `/dashboard/heim`;
        } else {
          Navigate(`/verify-otp/${payload.email}/2fa`);
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.error ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };
  return { Signin, setLoading, loading };
}
export default useSignin;
