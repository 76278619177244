import { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";

function useTicketChatSocket() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState([]);
  const { chatRef } = useContext(GlobalContext);
  const ConnectWebSockets = () => {
    chatRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "debug") {
        console.log("debug");
      } else {
        console.log("message");
        console.log("Received data:", data);
        setMessage((prevMessage) => [...prevMessage, data]);
      }
      if (data.type === "error") {
        console.log("error");
      }
    };

    chatRef.current.onclose = (e) => {
      console.log("close");
    };

    chatRef.current.onerror = (err) => {
      console.log("onError");
    };
  };
  const SubmitMessage = (payload) => {
    chatRef.current.send(JSON.stringify(payload));
    setLoading(false);
  };

  return {
    ConnectWebSockets,
    SubmitMessage,
    setLoading,
    loading,
    message,
  };
}

export default useTicketChatSocket;
