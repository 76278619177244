import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import useGetProfile from "./useGetProfile";
import { useNavigate } from "react-router-dom";

const useVerifyOtp = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const { fetchProfile } = useGetProfile();
  const Navigate = useNavigate();

  const verifyOTP = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/users/tf/authentication/`, payload)
      .then((response) => {
        sessionStorage.setItem("token", response.data.access);
        sessionStorage.setItem("refresh_token", response.data.refresh);
        if (response.data.access) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            text: response.data.message,
            customClass: {
              confirmButton: "custom-green-button",
            },
          });
          fetchProfile();
          Navigate(`/dashboard/heim`);
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.error ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, verifyOTP };
};

export default useVerifyOtp;
