// src/pages/EmailRoutingSettings.js
import React, { useState } from "react";

const EmailRoutingSettings = () => {
  const [settings, setSettings] = useState([
    { id: 1, name: "Primäre Route", status: "Aktiv" },
    { id: 2, name: "Backup-Route", status: "Inaktiv" },
  ]);

  const toggleStatus = (id) => {
    setSettings((prevSettings) =>
      prevSettings.map((setting) =>
        setting.id === id
          ? {
              ...setting,
              status: setting.status === "Aktiv" ? "Inaktiv" : "Aktiv",
            }
          : setting
      )
    );
  };

  return (
    <main className="dark:text-white">
      <h2 className="text-xl font-bold  mb-5">E-Mail-Routing-Einstellungen</h2>
      <div className="p-6 w-full mx-auto bg-white dark:bg-gray-700 shadow-md rounded-lg">
        <ul>
          {settings.map((setting) => (
            <li
              key={setting.id}
              className="flex justify-between items-center p-4 mb-2 border-b border-gray-200"
            >
              <span>{setting.name}</span>
              <button
                onClick={() => toggleStatus(setting.id)}
                className={`py-1 px-3 rounded ${
                  setting.status === "Aktiv"
                    ? "bg-green-500 text-white"
                    : "bg-red-500 text-white"
                }`}
              >
                {setting.status}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </main>
  );
};

export default EmailRoutingSettings;
