import React, { useContext } from "react";
import {
  PieChart as RechartsPieChart, // Renaming Recharts PieChart to avoid conflict
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import GlobalContext from "../../context/GlobalContext";

// Renaming the custom PieChart component
const CustomPieChart = ({ data }) => {
  const { dark } = useContext(GlobalContext);
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <RechartsPieChart>
        <Pie
          dataKey="value"
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={100}
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
