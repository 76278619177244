import React, { useContext } from "react";
import { Progress } from "antd"; // For the progress bar (if using Ant Design)
import GlobalContext from "../../context/GlobalContext";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const SchoolEmailUsageWidget = () => {
  const { dark } = useContext(GlobalContext);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/dashboard/abonnement/email");
  };
  const schoolEmailUsageData = {
    schoolName: "Greenfield High School",
    totalQuota: 5000,
    emailsSent: 3500,
  };

  const percentageUsed =
    (schoolEmailUsageData.emailsSent / schoolEmailUsageData.totalQuota) * 100;
  const remainingQuota =
    schoolEmailUsageData.totalQuota - schoolEmailUsageData.emailsSent;

  return (
    <div className="w-full bg-white dark:bg-gray-700  dark:text-white px-5 py-7 rounded mt-5">
      <h2 className="text-xl font-bold mb-2 text-left">E-Mail-Nutzung</h2>

      <div className="flex flex-col items-center mb-4">
        <p className="mb-2 text-gray-700 dark:text-white">
          <strong>{schoolEmailUsageData.emailsSent}</strong> /{" "}
          {schoolEmailUsageData.totalQuota} verwendete E-Mails
        </p>

        <Progress
          type="circle"
          percent={Math.round(percentageUsed)}
          format={(percent) => (
            <span className="text-gray-700 dark:text-white">{percent}%</span>
          )}
          width={120}
          strokeColor={dark ? "#fff" : "#374151 "}
        />
      </div>

      <div className="text-center text-gray-600 mt-4 dark:text-white">
        <p>
          <strong>{remainingQuota}</strong> verbleibende E-Mails
        </p>
      </div>
      <div className="w-full flex items-end justify-end">
        <button
          type="button"
          class="text-white bg-blue-700  hover:bg-blue-800
        focus:ring-4 focus:ring-blue-300 font-medium
        rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600
         dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 
         flex items-center justify-center gap-1"
          onClick={handleButtonClick}
        >
          <BiPurchaseTagAlt size={18} />
          Kauf
        </button>
      </div>
    </div>
  );
};

export default SchoolEmailUsageWidget;
