import React, { useContext } from "react";
import PaymentReminderSettings from "../../components/paymentReminderSettings";
import NotificationPreferences from "../../components/notificationReminder";
import ReminderLogs from "../../components/reminderLogs";
import GlobalContext from "../../context/GlobalContext";

const PaymentReminder = () => {
  const { userInfo } = useContext(GlobalContext);
  return (
    <main>
      <div className=" dark:text-white">
        {/* Frequently asked questions */}
        <h2 className="text-xl font-bold">Zahlungserinnerung</h2>
        <div className="mt-6 mb-4 md:p-8 p-3 bg-white dark:bg-gray-700 rounded-[8px] flex items-start flex-col gap-6 w-full">
          {userInfo?.role === "Admin" ? (
            <>
              <PaymentReminderSettings />
              <ReminderLogs />
            </>
          ) : (
            <NotificationPreferences />
          )}
        </div>
      </div>
    </main>
  );
};

export default PaymentReminder;
