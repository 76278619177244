import React from "react";
import Navbar from "../../landing-page/Navbar";
import Footer from "../../landing-page/Footer";
import DarkModeToggle from "../../../components/darkmode-toggle/darkmode-toggle";

const TermsCondition = () => {
  return (
    <div
      className="min-h-screen bg-gray-100 dark:bg-gray-700 dark:text-white"
      style={{ maxHeight: "100vh", overflowY: "auto" }}
    >
      {/* Navigation */}
      <Navbar />

      <div className="py-10 lg:px-14 xs:px-7 w-full text-justify">
        <h1 className="font-semibold text-[20px] text-center mb-8">
          Lorem Ipsum Dolor
        </h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
          facilisi. Vivamus sit amet fringilla nunc, id varius libero. Nam in
          eros sit amet ipsum gravida egestas.
        </p>

        <h1 className="text-[#DB0000] dark:text-gray-300 my-6 font-semibold text-center">
          LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT.
        </h1>
        <div>
          <li>
            "Lorem Ipsum"; "sit"; "amet"; "consectetur"; means lorem ipsum dolor
            sit amet.
          </li>
          <li>
            "Dolor"; "adipiscing"; "elit"; means consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </li>
          <li>
            "Eiusmod"; "tempor"; "incididunt" means ut labore et dolore magna
            aliqua, consectetur adipiscing elit.
          </li>
        </div>
        <h1 className="font-semibold text-[16px] my-4">1. Lorem Ipsum Terms</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
          facilisi. Fusce ut massa a turpis ultricies sagittis ut at lacus. Cras
          auctor, ex vel tincidunt scelerisque, nisl dui viverra lacus, vel
          tempus eros urna sit amet metus. Donec venenatis, nisi ac tempor
          tincidunt, sapien erat suscipit justo, vitae fringilla lectus dolor at
          nulla.
        </p>
        <p className="mt-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
          vehicula lectus ut nulla viverra, id laoreet justo sagittis. Nullam
          quis tellus in nisi aliquam ullamcorper et non risus.
        </p>
        <p className="mt-4">
          Curabitur in ex a metus consequat fringilla. Vestibulum venenatis
          risus nec sem volutpat, a ultricies purus dapibus. Suspendisse
          interdum dolor ac orci venenatis, eget elementum nisl vestibulum.
        </p>
        <p className="mt-4">
          Suspendisse potenti. Nulla efficitur metus in augue tristique, vel
          varius nisi pretium. Quisque et nisl consectetur, gravida libero non,
          porttitor justo.
        </p>
        <p className="mt-4">
          Quisque tincidunt massa nec est dictum, et finibus leo vehicula. Nulla
          facilisi. Nulla vestibulum, sapien sit amet convallis egestas, purus
          ipsum scelerisque lacus, ac lobortis eros elit ut augue.
        </p>
        <p className="mt-4">
          Nam nec mauris purus. Nulla auctor dui at lacus lacinia, non eleifend
          eros pharetra. Vestibulum ante ipsum primis in faucibus orci luctus et
          ultrices posuere cubilia curae.
        </p>
      </div>
      <DarkModeToggle />
      <Footer />
    </div>
  );
};

export default TermsCondition;
