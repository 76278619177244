import { useContext, useState } from "react";
import axios from "axios";
import GlobalContext from "../context/GlobalContext";
import { useLocation } from "react-router-dom";

function useGetOnBoarding() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("token");
  const { userInfo } = useContext(GlobalContext);
  const [onBoardingList, setOnBoardingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const GetOnBoarding = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/users/onboarding/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOnBoardingList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const addOnboardingPage = async (pageName) => {
    try {
      if (pageName) {
        await axios.post(
          `${BASE_URL}/users/onboarding/`,
          { page_name: pageName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    } catch (error) {
      console.error("Error marking onboarding as complete:", error);
    }
  };
  const markOnboardingComplete = async (pageName) => {
    try {
      if (pageName) {
        await axios.put(
          `${BASE_URL}/users/onboarding/`,
          { page_name: pageName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    } catch (error) {
      console.error("Error marking onboarding as complete:", error);
    }
  };

  return {
    GetOnBoarding,
    addOnboardingPage,
    markOnboardingComplete,
    onBoardingList,
    loading,
  };
}

export default useGetOnBoarding;
