import React, { useState } from "react";
import PaymentCard from "../../components/paymentCard";
import { useParams } from "react-router-dom";
import UpgradeModel from "../../components/upgradeModal";

const cards = [
  {
    id: 1,
    name: "Basic Plan",
    amount: 49,
    items: [
      { name: "Lorem Ipsum Lorem Ipsum", status: true },
      { name: "Lorem Ipsum Lorem Ipsum", status: true },
      { name: "Lorem Ipsum Lorem Ipsum", status: true },
      { name: "Lorem Ipsum Lorem Ipsum", status: false },
    ],
  },
  {
    id: 2,
    name: "Standard Plan",
    amount: 100,
    items: [
      { name: "Lorem Ipsum Lorem Ipsum", status: true },
      { name: "Lorem Ipsum Lorem Ipsum", status: true },
      { name: "Lorem Ipsum Lorem Ipsum", status: true },
      { name: "Lorem Ipsum Lorem Ipsum", status: true },
    ],
  },
  {
    id: 3,
    name: "Premium Plan",
    amount: 150,
    items: [
      { name: "Lorem Ipsum Lorem Ipsum", status: true },
      { name: "Lorem Ipsum Lorem Ipsum", status: true },
      { name: "Lorem Ipsum Lorem Ipsum", status: true },
      { name: "Lorem Ipsum Lorem Ipsum", status: true },
    ],
  },
];

const Subscription = () => {
  const { type } = useParams();
  const [discountCode, setDiscountCode] = useState("");
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const discountPercentage = 10; // 10% discount for valid code
  const validDiscountCode = "DISCOUNT10";

  const handleApplyDiscount = () => {
    if (discountCode === validDiscountCode) {
      setIsDiscountApplied(true);
    } else {
      alert("Invalid discount code.");
    }
  };

  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };

  const handleConfirmChange = () => {
    // Logic to handle the subscription change
    alert(`Subscription changed to ${selectedPlan.name} plan.`);
    setShowModal(false);
  };

  return (
    <main>
      <div className="flex flex-col justify-between items-start gap-3">
        <h2 className="text-xl font-bold dark:text-white">
          {type === "email"
            ? "Kaufen Sie Ihr E-Mail-Nutzungspaket"
            : "Wählen Sie Ihren Plan"}
        </h2>

        {/* Discount Code Section */}
        <div className="w-full lg:w-1/2 flex items-end gap-3 mt-4">
          <div>
            <label className="text-lg font-semibold dark:text-white">
              Rabattcode
            </label>
            <input
              type="text"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
              className="p-2 border rounded-md w-full outline-none mt-1"
              placeholder="Rabattcode eingeben..."
            />
          </div>

          <button
            onClick={handleApplyDiscount}
            className="h-[42px] p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Anwenden
          </button>
        </div>

        {/* Payment Cards */}
        <div className="flex lg:flex-row flex-col justify-center items-center gap-5 w-full mt-6">
          {cards.map((card) => {
            const finalPrice = isDiscountApplied
              ? card.amount - (card.amount * discountPercentage) / 100
              : card.amount;

            return (
              <div key={card.id}>
                <PaymentCard card={{ ...card, amount: finalPrice }} />
                {type === "school" && (
                  <button
                    onClick={() => handlePlanChange(card)}
                    className=" mt-3 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center bg-green-500 text-white hover:bg-green-600"
                  >
                    {card.amount > 49 ? "Upgrade" : "Downgrade"}
                  </button>
                )}
              </div>
            );
          })}
        </div>

        {/* Confirmation Modal */}
        {showModal && selectedPlan && (
          <UpgradeModel
            setShow={setShowModal}
            selectedPlan={selectedPlan}
            handleConfirmChange={handleConfirmChange}
          />
        )}
      </div>
    </main>
  );
};

export default Subscription;
