// import React, { useContext, useEffect, useState, useCallback } from "react";
// import { Navigate, useLocation } from "react-router-dom";
// import useGetProfile from "../hooks/useGetProfile";
// import {
//   ButtonLoader1,
//   ButtonLoader2,
// } from "../components/buttonLoader/ButtonLoader";

// const ProtectedRoute = ({ element }) => {
//   const { fetchProfile, loading } = useGetProfile();
//   const [authenticated, setAuthenticated] = useState(false);
//   const location = useLocation();
//   const token = sessionStorage.getItem("token");
//   console.log({ token });
//   useEffect(() => {
//     fetchProfile();
//   }, [token]);

//   useEffect(() => {
//     const updateLocalStorage = () => {
//       const isItemTrue = JSON.parse(
//         localStorage.getItem("isOnboardingComplete")
//       );
//       if (isItemTrue) {
//         localStorage.setItem("isOnboardingComplete", JSON.stringify(false));
//       }
//     };
//     updateLocalStorage();
//   }, [location]);

//   if (loading) {
//     return (
//       <main className="overflow-hidden h-screen">
//         <div className="w-full h-full flex dark:hidden items-center justify-center ">
//           <ButtonLoader1 />
//         </div>
//         <div className="w-full h-full dark:flex hidden items-center justify-center bg-gray-800">
//           <ButtonLoader2 />
//         </div>
//       </main>
//     );
//   }

//   return element;
// };

// export default ProtectedRoute;
import React from "react";
import { Navigate } from "react-router-dom";
const ProtectedRoute = ({ children }) => {
  const token = sessionStorage.getItem("token");
  return token ? children : <Navigate to="/" />;
};
export default ProtectedRoute;
