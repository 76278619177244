import React, { useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ButtonLoader2 } from "../../components/buttonLoader/ButtonLoader";
import useClassManagement from "../../hooks/useClassManagement";
import OnBoardingInstruction from "../../components/onBoardingInstruction";
import rawData from "../../components/rawData";
import useGetClasses from "../../hooks/useGetClasses";
const { onboarding_class_manage, onboarding_class_manage_last } = rawData;
const formSchema = Yup.object({
  name: Yup.string().required("name ist erforderlich"),
  email: Yup.string().required("e-mail ist erforderlich"),
  class_name: Yup.string().required("klasse-name ist erforderlich"),
});

const initialValues = {
  name: "",
  email: "",
  class_name: "",
};

const ClassManagement = () => {
  const { loading, ClassManage } = useClassManagement();
  const { GetClasses, tableData } = useGetClasses();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("token");
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      ClassManage({
        admin_name: values.name,
        admin_email: values.email,
        id: values.class_name,
      });
      formik.resetForm();
    },
  });
  const isSubmitDisabled = !formik.isValid || formik.isSubmitting;
  const handleCancel = (e) => {
    e.preventDefault();
    formik.resetForm();
  };
  useEffect(() => {
    GetClasses();
  }, []);
  return (
    <main>
      <OnBoardingInstruction
        onboarding_instruction={onboarding_class_manage}
        onboarding_instruction_last={onboarding_class_manage_last}
      />
      <div className="flex justify-between items-center gap-3">
        <h2 className="text-xl font-bold dark:text-white">Klasse Management</h2>
      </div>
      <div className="mt-6 mb-4 md:p-8 p-3 bg-white dark:bg-gray-700 rounded-[8px]">
        <form
          className="w-full py-5 gap-5 flex flex-col"
          onSubmit={formik.handleSubmit}
        >
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
            <p className="text-[12px] lg:text-[16px] py-3 text-left w-[200px] px-3">
              Admin-Name
            </p>
            <input
              type="text"
              className="input text-[12px] lg:text-[16px] outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300  w-full rounded-[6px]"
              name="name"
              placeholder="geben sie den administrator ein name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
          {formik.errors.name && formik.touched.name && (
            <div className="w-full flex items-end justify-start">
              <p className="text-red-700 text-xs">{formik.errors.name}</p>
            </div>
          )}
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
            <p className=" py-3 text-left w-[200px] px-3 text-[12px] lg:text-[16px]">
              Admin-E-Mail
            </p>

            {/* <select
              name="email"
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              className="block h-[45px] px-4 w-full text-sm text-gray-900 rounded-md bg-[#e5e7eb] dark:bg-gray-500 outline-none dark:text-white appearance-none focus:border-none"
            >
              <option value="" disabled>
                e-mail auswählen
              </option>
            
              {tableData.map((data, i) => (
                <option value={data.address} key={i}>
                  {data.address}
                </option>
              ))}
            </select> */}
            <input
              type="email"
              className="input text-[12px] lg:text-[16px] outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300  w-full rounded-[6px]"
              name="email"
              placeholder="e-mail auswählen"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
          {formik.errors.email && formik.touched.email && (
            <div className="w-full flex items-end justify-start">
              <p className="text-red-700 text-xs mt-1">{formik.errors.email}</p>
            </div>
          )}

          <div className="select dark:select-dark rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center ">
            <p className="py-3 text-left w-[200px] px-3 text-[12px] lg:text-[16px]">
              Klasse Name
            </p>
            <select
              name="class_name"
              value={formik.values.class_name}
              onChange={(e) =>
                formik.setFieldValue("class_name", e.target.value)
              }
              className="block h-[45px] px-4 w-full text-sm text-gray-900 rounded-md bg-[#e5e7eb] dark:bg-gray-500 outline-none dark:text-white appearance-none border-none"
            >
              <option value="" disabled>
                klasse auswählen
              </option>
              {tableData.map((data, i) => (
                <option value={data.id} key={i}>
                  {data.label}
                </option>
              ))}
            </select>
          </div>

          {formik.errors.class_name && formik.touched.class_name && (
            <div className="w-full flex items-end justify-start">
              <p className="text-red-700 text-xs mt-1">
                {formik.errors.class_name}
              </p>
            </div>
          )}

          <div className=" w-full flex flex-col items-end justify-end mt-28">
            <div className="flex items-center justify-center gap-4 mt-2">
              <button
                type="button"
                onClick={handleCancel}
                className="bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300 text-[12px] lg:text-[16px]"
              >
                Stornieren
              </button>
              <button
                type="submit"
                className={`py-2 px-4 rounded-md transition duration-300 text-[12px] h-[40px] w-[120px] flex items-center justify-center lg:text-[16px] ${
                  !isSubmitDisabled
                    ? "bg-indigo-600 text-white hover:bg-indigo-700"
                    : "bg-gray-300 text-gray-500 cursor-not-allowed"
                }`}
                disabled={isSubmitDisabled}
              >
                {loading ? <ButtonLoader2 /> : "Einreichen"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default ClassManagement;
