// import { useFormik } from "formik";
// import React, { useState } from "react";
// import * as Yup from "yup";
// import { ButtonLoader2 } from "../../components/buttonLoader/ButtonLoader";
// import { IoIosAdd } from "react-icons/io";
// import useSchoolStructure from "../../hooks/useSchoolStructure";

// const formSchema = Yup.object({
//   grades: Yup.array()
//     .of(
//       Yup.object({
//         grade: Yup.string().required("Note ist erforderlich"),
//         classes: Yup.array().of(
//           Yup.string().required("Klasse ist erforderlich")
//         ),
//       })
//     )
//     .required("Noten sind erforderlich"),
//   emailSuffix: Yup.string().required("E-Mail-Suffix ist erforderlich"),
// });

// const initialValues = {
//   grades: [{ grade: "", classes: [""] }],
//   emailSuffix: "",
// };

// const SchoolStructure = () => {
//   const [show, setShow] = useState(false);
//   const { SchoolStructureSubmit, loading } = useSchoolStructure();

//   const handleMenu = () => {
//     setShow(!show);
//   };

//   const formik = useFormik({
//     initialValues,
//     validationSchema: formSchema,
//     onSubmit: async (values) => {
//       await SchoolStructureSubmit({
//         grades: values.grades,
//         emailSuffix: values.emailSuffix,
//       });
//       resetForm();
//     },
//   });

//   const {
//     values,
//     errors,
//     handleChange,
//     handleSubmit,
//     touched,
//     setFieldValue,
//     resetForm,
//   } = formik;

//   const handleCancel = () => {
//     resetForm();
//   };

//   const addGrade = () => {
//     setFieldValue("grades", [...values.grades, { grade: "", classes: [""] }]);
//   };

//   const addClass = (gradeIndex) => {
//     const newGrades = [...values.grades];
//     newGrades[gradeIndex].classes.push("");
//     setFieldValue("grades", newGrades);
//   };

//   return (
//     <main>
//       <div className="flex justify-between items-center gap-3">
//         <h2 className="text-xl font-bold dark:text-white">Schule Struktur</h2>
//       </div>

//       <div className="card mt-6 mb-4 bg-white dark:bg-gray-700 md:p-8 p-3 rounded-[8px] h-[77vh] overflow-y-auto">
//         <form onSubmit={handleSubmit} className="form mt-4 flex flex-col gap-4">
//           <div className="space-y-1 w-full">
//             {values.grades.map((grade, gradeIndex) => (
//               <div key={gradeIndex} className="space-y-2 w-full">
//                 <div className="input-holder rounded-[6px]  dark:bg-gray-600 overflow-hidden bg-gray-100 flex justify-between items-center">
//                   <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
//                     Grad {gradeIndex + 1}
//                   </p>
//                   <input
//                     type="text"
//                     name={`grades[${gradeIndex}].grade`}
//                     value={grade.grade}
//                     onChange={handleChange}
//                     placeholder="geben sie die note ein..."
//                     className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-200 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
//                   />
//                 </div>
//                 {errors.grades?.[gradeIndex]?.grade &&
//                   touched.grades?.[gradeIndex]?.grade && (
//                     <p className="text-red-700 text-xs">
//                       {errors.grades[gradeIndex].grade}
//                     </p>
//                   )}
//                 {grade.classes.map((classItem, classIndex) => (
//                   <div
//                     key={classIndex}
//                     className="input-holder rounded-[6px]  dark:bg-gray-600 overflow-hidden bg-gray-100 flex justify-between items-center"
//                   >
//                     <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
//                       Klasse
//                       {classIndex + 1}
//                     </p>
//                     <input
//                       type="text"
//                       name={`grades[${gradeIndex}].classes[${classIndex}]`}
//                       value={classItem}
//                       onChange={handleChange}
//                       placeholder="betrete die klasse..."
//                       className="input outline-none border-none  dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-200 py-3 px-6 bg-gray-200 w-full rounded-[6px]"
//                     />
//                   </div>
//                 ))}
//                 <div className="flex items-end w-full justify-end">
//                   <button
//                     type="button"
//                     onClick={() => addClass(gradeIndex)}
//                     className="mt-2 p-1 bg-blue-500  dark:bg-gray-200 dark:hover:bg-gray-500 dark:hover:text-white dark:text-gray-700 hover:bg-blue-400 flex items-center text-white rounded"
//                   >
//                     <IoIosAdd className="text-xl " /> Klasse
//                   </button>
//                 </div>
//               </div>
//             ))}

//             <div className="flex items-end w-full justify-end">
//               <button
//                 type="button"
//                 onClick={addGrade}
//                 className="p-2 flex items-center border rounded-md hover:bg-gray-200 dark:hover:text-gray-700 mt-3 dark:text-white"
//               >
//                 <IoIosAdd className="text-xl" /> Grad
//               </button>
//             </div>
//           </div>

//           <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100  dark:bg-gray-600 flex justify-between items-center mt-2">
//             <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
//               E-Mail-Suffix
//             </p>
//             <input
//               type="text"
//               name="emailSuffix"
//               value={values.emailSuffix}
//               onChange={handleChange}
//               placeholder="geben sie das e-mail-suffix ein..."
//               className="input outline-none border-none py-3 px-6 bg-gray-200 w-full dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-200 rounded-[6px]"
//             />
//           </div>
//           {errors.emailSuffix && touched.emailSuffix && (
//             <div className="w-full flex items-end justify-end">
//               <p className="text-red-700 text-xs">{errors.emailSuffix}</p>
//             </div>
//           )}

//           <div className="flex items-end justify-end w-full gap-4 mt-6">
//             <button
//               type="button"
//               onClick={handleCancel}
//               className="bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300"
//             >
//               Stornieren
//             </button>
//             <button
//               type="submit"
//               className="bg-indigo-600 text-white py-2 px-7 h-[40px] w-[120px] flex items-center justify-center rounded-md hover:bg-indigo-700 transition duration-300"
//             >
//               {loading ? <ButtonLoader2 /> : "Einreichen"}
//             </button>
//           </div>
//         </form>
//       </div>
//     </main>
//   );
// };

// export default SchoolStructure;
import React, { useState } from "react";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import AccentButton from "../../components/widgets/buttons";
import Tree from "react-d3-tree";
import * as XLSX from "xlsx";
import template from "./import_structure.xlsx";
import axios from "axios";

import rawData from "../../components/rawData";
import OnBoardingInstruction from "../../components/onBoardingInstruction";
import useSchoolStructure from "../../hooks/useSchoolStructure";
import { ButtonLoader2 } from "../../components/buttonLoader/ButtonLoader";

const { onboarding_Structure, onboarding_Structure_last } = rawData;

const initialCommittees = [
  { name: "Schulkonferenz", abbreviation: "sk" },
  { name: "Gesamtkonferenz", abbreviation: "gk" },
  { name: "Fachkonferenzen", abbreviation: "fk" },
  { name: "Schülerrat", abbreviation: "sr" },
  { name: "Schulpflegschaft", abbreviation: "sp" },
  { name: "Klassenpflegschaften", abbreviation: "kp" },
  { name: "Förderverein", abbreviation: "fv" },
  { name: "Klassenkonferenz", abbreviation: "kk" },
  { name: "Lehrerkonferenz", abbreviation: "lk" },
  { name: "Schulträgerausschuss", abbreviation: "sa" },
  { name: "Vertrauenslehrer", abbreviation: "vl" },
];

const umlautMap = {
  ä: "ae",
  ö: "oe",
  ü: "ue",
  Ä: "Ae",
  Ö: "Oe",
  Ü: "Ue",
  ß: "ss",
};

const replaceUmlauts = (str) => {
  if (typeof str !== "string") {
    return str;
  }
  return str.replace(/[äöüÄÖÜß]/g, (match) => umlautMap[match]);
};

const SchoolStructure = () => {
  const [schoolAbbreviation, setSchoolAbbreviation] = useState("xyz"); // Replace 'xyz' with your school abbreviation
  const [classes, setClasses] = useState([]);
  const [committees, setCommittees] = useState([]);
  const [customCommittees, setCustomCommittees] = useState([]);
  const [useAbbreviations, setUseAbbreviations] = useState(true);
  const [viewMode, setViewMode] = useState("edit"); // 'edit' or 'visualize'
  const [zoom, setZoom] = useState(1);
  const [isActive, setIsActive] = useState(1);
  const { loading, SchoolStructureSubmit } = useSchoolStructure();

  const handleClassChange = (index, value) => {
    const newClasses = [...classes];
    newClasses[index].name = value;
    setClasses(newClasses);
  };

  const handleDepthChange = (classIndex, depthIndex, field, value) => {
    const newClasses = [...classes];
    newClasses[classIndex].depth[depthIndex][field] = value;
    setClasses(newClasses);
  };

  const addClass = () => {
    setClasses([
      ...classes,
      { name: "", depth: [{ label: "", students: "" }] },
    ]);
  };

  const removeClass = (index) => {
    const newClasses = [...classes];
    newClasses.splice(index, 1);
    setClasses(newClasses);
  };

  const addDepth = (classIndex) => {
    const newClasses = [...classes];
    newClasses[classIndex].depth.push({ label: "", students: "" });
    setClasses(newClasses);
  };

  const removeDepth = (classIndex, depthIndex) => {
    const newClasses = [...classes];
    newClasses[classIndex].depth.splice(depthIndex, 1);
    setClasses(newClasses);
  };

  const handleCommitteeChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCommittees([...committees, value]);
    } else {
      setCommittees(committees.filter((committee) => committee !== value));
    }
  };

  const handleCustomCommitteeChange = (index, field, value) => {
    const newCustomCommittees = [...customCommittees];
    newCustomCommittees[index] = {
      ...newCustomCommittees[index],
      [field]: value,
    };
    setCustomCommittees(newCustomCommittees);
  };

  const addCustomCommittee = () => {
    setCustomCommittees([...customCommittees, { name: "", abbreviation: "" }]);
  };

  const removeCustomCommittee = (index) => {
    const newCustomCommittees = [...customCommittees];
    newCustomCommittees.splice(index, 1);
    setCustomCommittees(newCustomCommittees);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setViewMode("visualize");

    const payload = {
      depth: classes.flatMap((classItem) =>
        classItem.depth.map((depthItem) => ({
          label: depthItem.label,
          students: depthItem.students,
        }))
      ),
      name: classes[0].name,
      committies: committees,
      custom_committies: customCommittees,
    };
    SchoolStructureSubmit(payload);
  };
  const generateEmail = (name, depth) => {
    return `${schoolAbbreviation}-${replaceUmlauts(name)}${replaceUmlauts(
      depth
    )}@klassenbote.de`;
  };

  const generateCommitteeEmail = (committee) => {
    const committeeObj = initialCommittees.find((c) => c.name === committee);
    let abbreviation = committeeObj
      ? committeeObj.abbreviation
      : replaceUmlauts(committee.replace(/\s+/g, "").toLowerCase());

    const customCommitteeObj = customCommittees.find(
      (c) => c.name === committee
    );
    if (customCommitteeObj && customCommitteeObj.abbreviation) {
      abbreviation = customCommitteeObj.abbreviation;
    }

    return `${schoolAbbreviation}-${
      useAbbreviations
        ? abbreviation
        : replaceUmlauts(committee.replace(/\s+/g, "").toLowerCase())
    }@klassenbote.de`;
  };

  const generateTreeData = () => {
    const classNodes = classes.map((classItem, classIndex) => {
      const totalStudents = classItem.depth.reduce(
        (acc, depthItem) => acc + parseInt(depthItem.students || 0, 10),
        0
      );
      return {
        name: `${
          classItem.name + ` Klasse` || `Klassenstufe Nr. ${classIndex + 1}`
        } (${totalStudents})`,
        children: classItem.depth.map((depthItem) => ({
          name: depthItem.label,
          attributes: {
            email: generateEmail(
              classItem.name || `Klassenstufe Nr. ${classIndex + 1}`,
              depthItem.label
            ),
            students: depthItem.students || "N/A",
          },
        })),
      };
    });

    const committeeNodes = [
      ...committees.map((committee) => ({
        name: committee,
        attributes: {
          email: generateCommitteeEmail(committee),
        },
      })),
      ...customCommittees
        .filter((c) => c.name)
        .map((customCommittee) => ({
          name: customCommittee.name,
          attributes: {
            email: generateCommitteeEmail(customCommittee.name),
          },
        })),
    ];

    const totalClassDepths = classes.reduce(
      (acc, classItem) =>
        acc +
        classItem.depth.reduce(
          (sum, depthItem) => sum + parseInt(depthItem.students || 0, 10),
          0
        ),
      0
    );

    const treeData = {
      name: `Schule (${totalClassDepths})`,
      children: [],
    };

    if (classNodes.length > 0) {
      treeData.children.push(...classNodes);
    }

    if (committeeNodes.length > 0) {
      treeData.children.push({
        name: "Gremien",
        children: committeeNodes,
      });
    }

    return treeData;
  };

  const renderRectSvgNode = ({ nodeDatum }) => (
    <g>
      <circle r={15} />
      <text fill="black" x="0" y="35" textAnchor="middle">
        {nodeDatum.name}
      </text>
      {nodeDatum.attributes && nodeDatum.attributes.email && (
        <text fill="gray" x="0" y="50" textAnchor="middle" fontSize="10">
          {nodeDatum.attributes.email}
        </text>
      )}
      {nodeDatum.attributes && nodeDatum.attributes.students && (
        <text fill="gray" x="0" y="65" textAnchor="middle" fontSize="10">
          Schüler: {nodeDatum.attributes.students}
        </text>
      )}
    </g>
  );

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom(zoom - 0.1);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(
        workbook.Sheets[firstSheetName],
        { header: 1 }
      );

      const newClasses = [];
      worksheet.slice(1).forEach((row) => {
        const [type, name, depthLabel, students, abbreviation] = row;
        if (type === "class") {
          let classIndex = newClasses.findIndex((c) => c.name === name);
          if (classIndex === -1) {
            newClasses.push({ name, depth: [{ label: depthLabel, students }] });
          } else {
            newClasses[classIndex].depth.push({ label: depthLabel, students });
          }
        } else if (type === "committee") {
          const committee = { name, abbreviation };
          setCustomCommittees((prev) => [...prev, committee]);
        }
      });

      setClasses(newClasses);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <main
      className="min-h-screen p-6 dark:text-white"
      style={{ maxHeight: "100vh", overflowY: "auto" }}
    >
      <OnBoardingInstruction
        onboarding_instruction={onboarding_Structure}
        onboarding_instruction_last={onboarding_Structure_last}
      />
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800 dark:text-white ">
          Schulstruktur erstellen
        </h1>
        <div className="flex justify-center mb-6">
          <button
            className={`px-4 py-2 rounded-l ${
              viewMode === "edit"
                ? "bg-blue-500 dark:bg-gray-600 text-white"
                : "bg-gray-300 dark:bg-gray-500 "
            }`}
            onClick={() => setViewMode("edit")}
          >
            Edit
          </button>
          <button
            className={`px-4 py-2 rounded-r ${
              viewMode === "visualize"
                ? "bg-blue-500 dark:bg-gray-600 text-white"
                : "bg-gray-300 dark:bg-gray-500 "
            }`}
            onClick={() => setViewMode("visualize")}
          >
            Visualize
          </button>
        </div>
        <div className="flex justify-center mb-6">
          <a
            href={template}
            download="import_structure.xlsx"
            className="dark:text-white dark:hover:text-gray-300 text-indigo-600 hover:text-indigo-800 focus:outline-none focus:underline transition-all duration-200 ease-in-out"
          >
            Vorlage herunterladen
          </a>
        </div>
        <div className="flex justify-center mb-6">
          <input type="file" onChange={handleFileUpload} />
        </div>
        {viewMode === "edit" ? (
          <form
            className="bg-white dark:bg-gray-700 dark:text-white p-6 rounded shadow-md space-y-4"
            onSubmit={handleSubmit}
          >
            <div className="space-y-4">
              {classes.map((classItem, classIndex) => (
                <div key={classIndex} className="border-b pb-4 mb-4">
                  <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-white ">
                      Klassenstufe Nr. {classIndex + 1}
                    </h2>
                    <button
                      type="button"
                      className="text-red-500 hover:text-red-700"
                      onClick={() => removeClass(classIndex)}
                    >
                      <FaTrash />
                    </button>
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-bold mb-2 text-gray-700 dark:text-white"
                      htmlFor={`class-name-${classIndex}`}
                    >
                      Klassenbezeichnung *
                    </label>
                    <input
                      type="text"
                      id={`class-name-${classIndex}`}
                      className="rounded w-full py-2 px-3 leading-tight dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 bg-gray-200 text-gray-800 border-2 border-transparent focus:border-indigo-600 outline-none transition-all duration-300 ease-out"
                      value={classItem.name}
                      onChange={(e) =>
                        handleClassChange(classIndex, e.target.value)
                      }
                      required
                      placeholder="Geben Sie die Klassenbezeichnung ein"
                    />
                  </div>
                  <div className="space-y-4">
                    {classItem.depth.map((depthItem, depthIndex) => (
                      <div
                        key={depthIndex}
                        className="flex items-center space-x-2"
                      >
                        <input
                          type="text"
                          className="flex-1 rounded py-2 px-3 dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 leading-tight bg-gray-200 text-gray-800 border-2 border-transparent focus:border-indigo-600 outline-none transition-all duration-300 ease-out"
                          value={depthItem.label}
                          onChange={(e) =>
                            handleDepthChange(
                              classIndex,
                              depthIndex,
                              "label",
                              e.target.value
                            )
                          }
                          required
                          placeholder="Geben Sie die Klassenstufe ein"
                        />
                        <input
                          type="number"
                          className="flex-1 rounded py-2 px-3 dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 leading-tight bg-gray-200 text-gray-800 border-2 border-transparent focus:border-indigo-600 outline-none transition-all duration-300 ease-out"
                          value={depthItem.students}
                          onChange={(e) =>
                            handleDepthChange(
                              classIndex,
                              depthIndex,
                              "students",
                              e.target.value
                            )
                          }
                          required
                          placeholder="Anzahl der Schüler"
                        />
                        <button
                          type="button"
                          className="text-red-500 hover:text-red-700"
                          onClick={() => removeDepth(classIndex, depthIndex)}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="flex items-center text-blue-500 dark:text-white dark:hover:text-gray-300 hover:text-blue-700"
                      onClick={() => addDepth(classIndex)}
                    >
                      <FaPlusCircle className="mr-2" /> Tiefe hinzufügen
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center">
              <button
                type="button"
                className="flex items-center dark:text-white dark:hover:text-gray-300 text-blue-500 hover:text-blue-700 mb-4"
                onClick={addClass}
              >
                <FaPlusCircle className="mr-2" /> Klassenstufe hinzufügen
              </button>
            </div>

            <div className="mt-8">
              <h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                Gremien
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {initialCommittees.map((committee) => (
                  <label
                    className="flex items-center text-gray-800 dark:text-white"
                    key={committee.name}
                  >
                    <input
                      type="checkbox"
                      value={committee.name}
                      onChange={handleCommitteeChange}
                      checked={committees.includes(committee.name)}
                      className="mr-2 dark:text-white"
                    />
                    {committee.name}
                  </label>
                ))}
              </div>
              <div className="mt-4 space-y-4">
                {customCommittees.map((customCommittee, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      className="flex-1 rounded py-2 px-3 leading-tight dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 bg-gray-200 text-gray-800 border-2 border-transparent focus:border-indigo-600 outline-none transition-all duration-300 ease-out"
                      value={customCommittee.name}
                      onChange={(e) =>
                        handleCustomCommitteeChange(
                          index,
                          "name",
                          e.target.value
                        )
                      }
                      placeholder="Eigenes Gremium"
                    />
                    <input
                      type="text"
                      className="flex-1 rounded py-2 px-3 leading-tight dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 bg-gray-200 text-gray-800 border-2 border-transparent focus:border-indigo-600 outline-none transition-all duration-300 ease-out"
                      value={customCommittee.abbreviation}
                      onChange={(e) =>
                        handleCustomCommitteeChange(
                          index,
                          "abbreviation",
                          e.target.value
                        )
                      }
                      placeholder="Abkürzung"
                    />
                    <button
                      type="button"
                      className="text-red-500 hover:text-red-700"
                      onClick={() => removeCustomCommittee(index)}
                    >
                      <FaTrash />
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="flex items-center text-blue-500 dark:text-white dark:hover:text-gray-300 hover:text-blue-700"
                  onClick={addCustomCommittee}
                >
                  <FaPlusCircle className="mr-2" /> Eigenes Gremium hinzufügen
                </button>
              </div>
            </div>

            <div className="text-center mt-8">
              <label className="flex items-center text-gray-800 dark:text-white">
                <input
                  type="checkbox"
                  checked={useAbbreviations}
                  onChange={(e) => setUseAbbreviations(e.target.checked)}
                  className="mr-2"
                />
                Gremienabkürzungen verwenden
              </label>
            </div>

            <div className="text-center mt-8">
              {loading ? (
                <ButtonLoader2 />
              ) : (
                <AccentButton text="Speichern" type="submit" />
              )}
            </div>
          </form>
        ) : (
          <div className="mt-6 bg-white p-6 rounded shadow-md">
            <div className="flex justify-between mb-4">
              <button
                className="text-blue-500 hover:text-blue-700"
                onClick={handleZoomIn}
              >
                Zoom In
              </button>
              <button
                className="text-blue-500 hover:text-blue-700"
                onClick={handleZoomOut}
              >
                Zoom Out
              </button>
            </div>
            <div id="treeWrapper" style={{ width: "100%", height: "500px" }}>
              <Tree
                data={generateTreeData()}
                orientation="vertical"
                pathFunc="step" // "diagonal" or "elbow" or "straight" or "step" or "arc"
                separation={{ siblings: 1.3, nonSiblings: 1.5 }}
                translate={{ x: 750, y: 50 }}
                zoom={zoom}
                renderCustomNodeElement={renderRectSvgNode}
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default SchoolStructure;
