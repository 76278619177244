import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuAnimateClass = isOpen ? "scale-y-100" : "scale-y-0";

  return (
    <nav className="bg-white dark:bg-gray-900 shadow-lg">
      <div className="max-w-6xl mx-auto px-4 py-6 flex justify-between items-center">
        <a href="/" className="text-xl font-bold text-indigo-600">
          Klassenbote
        </a>
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-gray-600 focus:outline-none"
          >
            <svg className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              )}
            </svg>
          </button>
        </div>
        <div
          className={`transform origin-top ${menuAnimateClass} transition-transform
           duration-300 ease-out md:scale-y-100 flex flex-col md:flex-row md:space-x-4 
           space-y-4 md:space-y-0 absolute md:relative top-16 md:top-0 right-0 w-full md:w-auto
           shadow-md md:shadow-none bg-gray-200 sm:bg-white py-5 sm:dark:bg-gray-900 dark:bg-gray-700`}
        >
          <a
            href="/"
            className="block px-4 py-2 text-base text-gray-600 hover:text-gray-800 dark:text-gray-100"
          >
            Home
          </a>
          <a
            href="/"
            className="block px-4 py-2 text-base text-gray-600 hover:text-gray-800 dark:text-gray-100"
          >
            Features
          </a>
          <a
            href="/"
            className="block px-4 py-2 text-base text-gray-600 hover:text-gray-800 dark:text-gray-100"
          >
            Pricing
          </a>
          <a
            href="/"
            className="block px-4 py-2 text-base text-gray-600 hover:text-gray-800 dark:text-gray-100"
          >
            Contact
          </a>
          <a
            href="/login"
            className="block px-4 py-2 text-base bg-indigo-600 text-white rounded-md hover:bg-indigo-700 dark:text-gray-100"
          >
            Log in
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
