import React, { createContext, useReducer, useRef } from "react";
import AppReducer from "./AppReducer";

const initialState = {
  userInfo: null,
  broadcastMessage: [],
  dark: false,
};

const GlobalContext = createContext(initialState);
export default GlobalContext;

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const setUserInfo = (userInfo) => {
    dispatch({
      type: "SET_USER_INFO",
      payload: userInfo,
    });
  };
  const setBroadcastMessage = (broadcastMessage) => {
    dispatch({
      type: "SET_BROADCAST_MESSAGE",
      payload: broadcastMessage,
    });
  };

  const removeBroadcastMessage = (id) => {
    dispatch({
      type: "REMOVE_BROADCAST_MESSAGE",
      payload: { id },
    });
  };
  const setDark = (dark) => {
    dispatch({
      type: "SET_DARK",
      payload: dark,
    });
  };
  const broadcastRef = useRef(null);
  const dashboardRef = useRef(null);
  const chatRef = useRef(null);

  return (
    <GlobalContext.Provider
      value={{
        userInfo: state.userInfo,
        setUserInfo,
        dark: state.dark,
        setDark,
        broadcastMessage: state.broadcastMessage,
        setBroadcastMessage,
        removeBroadcastMessage,
        broadcastRef,
        dashboardRef,
        chatRef,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
