import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ButtonLoader2 } from "../../components/buttonLoader/ButtonLoader";
import axios from "axios";
import { BiError } from "react-icons/bi";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import EditModel from "../../components/uploadFileEditer";
import rawData from "../../components/rawData";
import OnBoardingInstruction from "../../components/onBoardingInstruction";
import useUploadDocuments from "../../hooks/useUploadDocuments";
import Table from "../../components/uoloadDocTable";
import GlobalContext from "../../context/GlobalContext";
const { formInstructions, formInstructions_last } = rawData;
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
const formSchema = Yup.object({
  docTitle: Yup.string().required("der dokumenttitel ist erforderlich"),
  message: Yup.string(),
  file: Yup.array()
    .of(Yup.mixed())
    .nullable()
    .required("datei ist erforderlich"),
  tags: Yup.array().min(1, "At least one tag is required"),
});

const initialValues = {
  docTitle: "",
  message: "",
  tags: [],
  file: [],
};
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const UploadDocuments = () => {
  const { loading, UploadDoc } = useUploadDocuments();
  const [subscribed, setSubscribed] = useState(true);
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [showEditModel, setShowEditModel] = useState(false);
  const [ticketDetail, setTicketDetail] = useState(null);
  const [user, setUser] = useState("admin");
  const [tableData, setTableData] = useState([]);
  const [updatedDoc, setUpdatedDoc] = useState(false);
  const [tableLoad, setTableLoad] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [filteredData, setFilteredData] = useState(tableData);
  const [inputValue, setInputValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { userInfo } = useContext(GlobalContext);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      const newTags = [...formik.values.tags, inputValue.trim()];
      formik.setFieldValue("tags", newTags);
      setInputValue("");
    }
  };
  const handleRemoveTag = (index) => {
    const newTags = formik.values.tags.filter((_, i) => i !== index);
    formik.setFieldValue("tags", newTags);
  };
  const getDocs = async () => {
    setTableLoad(true);
    let params = {};
    params.start_date = dateRange[0]?.format("YYYY-MM-DD");
    params.end_date = dateRange[1]?.format("YYYY-MM-DD");
    const url = new URL(`${BASE_URL}/doc/upload-documents/`);
    if (params.start_date && params.end_date) {
      url.searchParams.append("start_date", params.start_date);
      url.searchParams.append("end_date", params.end_date);
    }
    await axios
      .get(url.toString(), {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })

      .then((response) => {
        setTableData(Array.isArray(response.data) ? response.data : []);
        setFilteredData(Array.isArray(response.data) ? response.data : []);
        setTableLoad(false);
        setUpdatedDoc(false);
      })

      .catch((error) => {
        setTableLoad(false);
      });
  };
  // pagination
  const rowsPerPage = 25;
  const paginationRef = useRef(null);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const currentData = filteredData?.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const scrollToActiveButton = (index) => {
    if (paginationRef.current) {
      const activeButton = paginationRef.current.children[index];
      if (activeButton) {
        activeButton.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      if (files.length === 0) {
        formik.setStatus({
          fileError: "Please select a file before submitting.",
        });
        return; // Prevent submission if no files
      }

      const formData = new FormData();
      formData.append("document_title", values.docTitle);
      formData.append("description", values.message);
      formData.append("keywords", JSON.stringify(values.tags)); // Convert tags to JSON

      files.forEach((file) => {
        formData.append("file", file);
      });

      try {
        await UploadDoc(formData, setUpdatedDoc);
        formik.resetForm();
        setFiles([]);
      } catch (error) {
        console.error("Upload error:", error);
      }
    },
  });
  const isSubmitDisabled = !formik.isValid || formik.isSubmitting;
  const handleCancel = (e) => {
    e.preventDefault();
    formik.resetForm();
    setFiles(null);
  };
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: "*/*", // Accept all file types
      multiple: false,
      onDrop: (acceptedFiles) => {
        const filteredFiles = acceptedFiles.filter((file) => {
          const miscExtensions = ["exe", "bat", "sh", "cmd"]; // Miscellaneous/unwanted file types
          const fileExtension = file.name.split(".").pop().toLowerCase();
          return !miscExtensions.includes(fileExtension);
        });
        setFiles(filteredFiles); // Set files only if they pass the filter
      },
    });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("token");
  useEffect(() => {
    getDocs();
  }, [updatedDoc, dateRange]);
  useEffect(() => {
    if (dateRange.length === 2) {
      const [startDate, endDate] = dateRange;
      const filtered = tableData.filter((item) => {
        const itemDate = dayjs(item.date);
        return (
          itemDate.isSameOrAfter(startDate, "day") &&
          itemDate.isSameOrBefore(endDate, "day")
        );
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(tableData);
    }
  }, [dateRange, tableData]);
  useEffect(() => {
    scrollToActiveButton(currentPage - 1);
  }, [currentPage]);

  return subscribed ? (
    <main>
      <OnBoardingInstruction
        onboarding_instruction={formInstructions}
        onboarding_instruction_last={formInstructions_last}
      />
      <div className="flex justify-between items-center gap-3">
        <h2 className="text-xl font-bold dark:text-white">
          Dokumente hochladen
        </h2>
      </div>
      <div className="mt-6 mb-4 md:p-8 p-3 bg-white dark:bg-gray-700 rounded-[8px]">
        {!(
          userInfo?.role === "Admin" ||
          userInfo.role === "super_admin" ||
          userInfo.role === "staff"
        ) && (
          <form
            className="w-full py-5 gap-5 flex flex-col"
            onSubmit={formik.handleSubmit}
          >
            <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
              <p className="text-[12px] lg:text-[16px] py-3 text-left w-[200px] px-3">
                Titel
              </p>
              <input
                type="text"
                className="input text-[12px] 
              lg:text-[16px] outline-none border-none
               p-3 bg-gray-200 dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 w-full rounded-[6px]"
                name="docTitle"
                placeholder="geben sie das dokument ein titel..."
                value={formik.values.docTitle}
                onChange={formik.handleChange}
              />
            </div>
            {formik.errors.docTitle && formik.touched.docTitle && (
              <div className="w-full flex items-end justify-start">
                <p className="dark:text-slate-100 text-red-700 dark:font-bold font-medium mt-1 text-xs flex items-center gap-1 justify-start">
                  <BiError className="text-red-700 text-[18px]" />
                  {formik.errors.docTitle}
                </p>
              </div>
            )}
            <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
              <p className=" py-3 text-left w-[200px] px-3 text-[12px] lg:text-[16px]">
                Beschreibung
              </p>
              <textarea
                type="text"
                className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px] resize-none text-[12px] lg:text-[16px] dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 "
                name="message"
                placeholder="geben sie die beschreibung ein..."
                value={formik.values.message}
                onChange={formik.handleChange}
              />
            </div>
            {formik.errors.message && formik.touched.message && (
              <div className="w-full flex items-end justify-start">
                <p className="dark:text-slate-100 text-red-700 dark:font-bold font-medium mt-1 text-xs flex items-center gap-1 justify-start">
                  <BiError className="text-red-700 text-[18px]" />
                  {formik.errors.message}
                </p>
              </div>
            )}
            <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white w-full flex justify-between items-center">
              <p className="py-3 text-left w-[200px] px-3 text-[12px] lg:text-[16px]">
                Schlagworte
              </p>
              <div
                className="input outline-none border-none bg-gray-200 w-full p-3
               text-[12px] lg:text-[16px] resize-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 flex items-center "
              >
                {formik.values.tags &&
                  formik.values.tags.map((tag, index) => (
                    <div
                      key={index}
                      className="flex items-center bg-gray-00 text-white rounded-full px-3 py-1 text-[12px] lg:text-[16px] dark:bg-gray-300"
                    >
                      {tag}
                      <button
                        onClick={() => handleRemoveTag(index)}
                        className="ml-2 text-white hover:text-gray-300"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                <input
                  type="text"
                  name="tags"
                  placeholder="geben Sie die Tags ein..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="ml-5 bg-gray-200 dark:bg-gray-500 outline-none"
                />
              </div>
            </div>
            <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
              <p className="text-[12px] lg:text-[16px] py-3 text-left w-[200px] px-3">
                Select Files
              </p>
              <div className="container bg-gray-200 dark:bg-gray-500 dark:text-white text-gray-400">
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <div className="flex flex-col items-center justify-center mt-3">
                    <p className="lg:text-xl text-[12px]">
                      Ziehen Sie einige PNG-, JPG-, JPEG- und PDF-Dateien per
                      Drag & Drop
                    </p>
                    <p className="lg:text-xl text-[12px]">or</p>
                    <button
                      type="button"
                      className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
                    >
                      Wählen
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {formik.status?.fileError && (
              <div className="w-full flex items-end justify-start">
                <p className="dark:text-slate-100 text-red-700 dark:font-bold font-medium mt-1 text-xs flex items-center gap-1 justify-start">
                  <BiError className="text-red-700 text-[18px]" />
                  {formik.status.fileError}
                </p>
              </div>
            )}

            <div className="-mt-1 w-full flex flex-col items-end justify-end">
              {files?.map((file, index) => (
                <div
                  key={index}
                  className="text-gray-700 text-sm dark:text-white"
                >
                  {file.name}
                </div>
              ))}
              <div className="flex items-center justify-center gap-4 mt-2">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300 text-[12px] lg:text-[16px]"
                >
                  Stornieren
                </button>
                <button
                  type="submit"
                  className={`py-2 px-4 rounded-md transition duration-300 text-[12px] h-[40px] w-[120px] flex items-center justify-center lg:text-[16px] ${
                    !isSubmitDisabled
                      ? "bg-indigo-600 text-white hover:bg-indigo-700"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed"
                  }`}
                  disabled={isSubmitDisabled}
                >
                  {loading ? <ButtonLoader2 /> : "Einreichen"}
                </button>
              </div>
            </div>
          </form>
        )}
        {(userInfo?.role === "Admin" ||
          userInfo.role === "super_admin" ||
          userInfo.role === "staff") && (
          <>
            <h2 className="text-xl font-bold dark:text-white">
              Unterlagen eingereicht
            </h2>
            <Table
              setShow={setShow}
              setRowData={setRowData}
              tableData={tableData}
              setShowEditModel={setShowEditModel}
              setTicketDetail={setTicketDetail}
              user={user}
              loading={tableLoad}
              getTickets={getDocs}
              currentData={currentData}
              handlePrevPage={handlePrevPage}
              paginationRef={paginationRef}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              handleNextPage={handleNextPage}
              currentPage={currentPage}
              dateRange={dateRange}
              setDateRange={setDateRange}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
            />
            {showEditModel && (
              <EditModel
                setShowEditModel={setShowEditModel}
                detail={ticketDetail}
                setUpdatedDoc={setUpdatedDoc}
              />
            )}
          </>
        )}
      </div>
    </main>
  ) : (
    <div className="flex items-center justify-center h-[75vh] flex-col pt-5">
      <h2 className="text-xl font-semibold dark:text-white">
        You have not subscribed yet!
      </h2>
      <h2 className="text-xl font-semibold dark:text-white">
        Subscribe first to view ticketsystem
      </h2>
      <button className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300">
        Subscribe
      </button>
    </div>
  );
};

export default UploadDocuments;
