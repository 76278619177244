import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import useGetOnBoarding from "../../hooks/useGetOnBoardnig";

const Onboarding = ({
  currentIndex,
  setCurrentIndex,
  setIsOnboardingComplete,
  onboarding,
  onboarding_last,
}) => {
  const {
    GetOnBoarding,
    addOnboardingPage,
    markOnboardingComplete,
    onBoardingList,
  } = useGetOnBoarding();
  const location = useLocation();

  const screens = [
    <OnboardingScreen1 location={location} />,
    <OnboardingScreen2 onboarding={onboarding} />,
    <OnboardingScreen3 onboarding_last={onboarding_last} />,
  ];

  const nextScreen = () => {
    if (currentIndex === screens.length - 1) {
      markOnboardingComplete(location.pathname.slice(1));
      setIsOnboardingComplete(true);
    } else {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % screens.length);
    }
  };

  const prevScreen = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + screens.length) % screens.length
    );
  };

  useEffect(() => {
    addOnboardingPage(location.pathname.slice(1));
    GetOnBoarding();
  }, [location.pathname]);

  return (
    <div
      className="bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 
      h-screen w-full fixed left-0 top-0 flex items-center justify-center text-white z-[9999999999]"
    >
      {/* Render only the current screen */}
      <div className="relative w-full h-full overflow-hidden flex justify-center items-center">
        <div className="transition-transform duration-500 ease-in-out w-full h-full flex items-center justify-center">
          {screens[currentIndex]}
        </div>

        {/* Navigation Buttons */}
        <div className="absolute bottom-5 left-0 right-0 flex justify-between px-4">
          {currentIndex > 0 && (
            <button
              className="text-gray-900 bg-gray-300 p-3 rounded-md"
              onClick={prevScreen}
            >
              Vorherige
            </button>
          )}
          <button
            className="text-gray-900 bg-gray-300 p-3 rounded-md"
            onClick={nextScreen}
          >
            {currentIndex === screens.length - 1 ? "Beenden" : "Nächste"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;

const OnboardingScreen1 = ({ location }) => {
  return (
    <div className="flex flex-col items-center justify-start overflow-scroll md:h-1/2 h-[60%] md:w-1/2 w-[90%] text-gray-800 dark:text-white rounded-2xl shadow-lg bg-gray-100 dark:bg-gray-800 border border-gray-900 dark:border-gray-200 ">
      <div className="p-6 w-full">
        <div className="flex items-start w-full justify-start gap-10 lg:flex-row-reverse flex-col ">
          <div className="mb-6 mt-2 w-full ">
            <h3 className="md:text-2xl text-xl font-bold text-center">
              Einarbeitungsanleitungen
            </h3>

            <div className="px-4  w-full text-left">
              <h3 className="text-1xl mt-2 font-bold  underline">
                Beschreibung:
              </h3>
              {location.pathname !== "/dashboard/schule-hinzufugen" &&
              location.pathname !== "/dashboard/dokumenten-bibliotek" &&
              location.pathname !== "/dashboard/vereinbarungen" &&
              location.pathname !== "/dashboard/klassenleitung" &&
              location.pathname !== "/dashboard/ticketsystem" &&
              location.pathname !== "/dashboard/frequently-asked-questions" &&
              location.pathname !== "/dashboard/settings" ? (
                <p className="mt-1  font-semibold">
                  Gibt einen kurzen Überblick über das Dashboard und erklärt
                  seine Hauptfunktionen, wie die Zeitrahmenoptionen zum Filtern
                  von Daten (täglich, wöchentlich, monatlich).
                </p>
              ) : (
                <p className="mt-1 font-semibold">
                  Bietet einen kurzen Überblick über die Seite und erklärt ihre
                  Hauptmerkmale.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const OnboardingScreen2 = ({ onboarding }) => {
  return (
    <div className="flex flex-col items-center justify-start overflow-scroll md:h-1/2 h-[60%] md:w-1/2 w-[90%] text-gray-800 dark:text-white rounded-2xl shadow-lg bg-gray-100 dark:bg-gray-800 border border-gray-900 dark:border-gray-200 ">
      <div className="p-6 w-full">
        <div className="flex items-start w-full justify-start gap-10 lg:flex-row-reverse flex-col ">
          <div className="mb-6 mt-2 w-full ">
            <h3 className="md:text-2xl text-xl font-bold  text-center">
              Wichtige Dashboard-Elemente Erklärt
            </h3>
            {onboarding.map((data, index) => (
              <div className="px-4  w-full text-left" key={index}>
                <h3 className="text-1xl mt-2 font-bold  underline">
                  {data.title} :
                </h3>
                <p className="mt-1  font-semibold">{data.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const OnboardingScreen3 = ({ onboarding_last }) => {
  return (
    <div className="flex flex-col items-center justify-start overflow-scroll md:h-1/2 h-[60%] md:w-1/2 w-[90%] text-gray-800 dark:text-white rounded-2xl shadow-lg bg-gray-100 dark:bg-gray-800 border border-gray-900 dark:border-gray-200 ">
      <div className="p-6 w-full">
        <div className="flex items-start w-full justify-start gap-10 lg:flex-row-reverse flex-col ">
          <div className="mb-6 mt-2 w-full ">
            <h3 className="md:text-2xl text-xl font-bold  text-center">
              Wichtige Dashboard-Elemente Erklärt
            </h3>
            {onboarding_last.map((data, index) => (
              <div className="px-4 w-full" key={index}>
                <h3 className="text-1xl mt-2 font-bold  underline">
                  {data.title} :
                </h3>
                <p className="mt-1 font-semibold">{data.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
