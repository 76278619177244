import React from "react";
import { IoIosCloseCircle } from "react-icons/io";

const UpgradeModel = ({ setShow, selectedPlan, handleConfirmChange }) => {
  return (
    <div className="w-screen h-screen bg-[#8383838c] flex items-center justify-center overflow-none z-50 fixed top-0 left-0">
      <div className="w-[90%] md:w-[60%] py-5 px-5 bg-white dark:bg-gray-800 dark:text-white relative rounded-md">
        <IoIosCloseCircle
          className="absolute -right-3 -top-2 text-2xl bg-white dark:bg-gray-700 rounded-full cursor-pointer"
          onClick={() => setShow(false)}
        />
        <h2 className="text-xl font-bold py-4">Confirm Subscription Change</h2>
        <div className="w-full flex items-center justify-center flex-col">
          <p>
            Are you sure you want to change to the{" "}
            <strong>{selectedPlan.name}</strong> plan for ${selectedPlan.amount}
            ?
          </p>
          <div className="flex gap-4 mt-4">
            <button
              onClick={handleConfirmChange}
              className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Confirm
            </button>
            <button
              onClick={() => setShow(false)}
              className="p-2 bg-gray-400 rounded-md hover:bg-gray-500"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeModel;
