import { useFormik } from "formik";
import React, { useState } from "react";
import useUpdatePassword from "../../hooks/useUpdatePassword";
import { BiHide, BiShow } from "react-icons/bi";
import { ButtonLoader2 } from "../../components/buttonLoader/ButtonLoader";
import * as Yup from "yup";

const initialValues_ForChangePassword = {
  className: "",
  prevPassword: "",
  password: "",
  confirm_password: "",
};
let Password_REGX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
const formSchema_ForChangePassword = Yup.object({
  className: Yup.string().required("Class name is required"),
  prevPassword: Yup.string().required("Aktuelles Passwort ist erforderlich"),
  password: Yup.string()
    .required("Passwort ist erforderlich")
    .matches(
      Password_REGX,
      "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben und mindestens eine Ziffer enthalten"
    ),
  confirm_password: Yup.string()
    .required("Zur Bestätigung ist ein Passwort erforderlich")
    .oneOf([Yup.ref("password"), null], "Passwörter müssen übereinstimmen"),
});
const ChangeClassPassword = () => {
  const [previousPassword, setPreviousPassword] = useState(false);
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const { passwordLoading, PasswordManage } = useUpdatePassword();

  const formikPassword = useFormik({
    initialValues: initialValues_ForChangePassword,
    validationSchema: formSchema_ForChangePassword,
    onSubmit: async (values) => {
      await PasswordManage({
        className: values.className,
        old_password: values.prevPassword,
        new_password: values.password,
      });
    },
  });

  const handleCancel = () => {
    formikPassword.resetForm();
  };
  return (
    <main>
      <div className=" dark:text-white">
        {/* Frequently asked questions */}
        <div className="flex items-center">
          <h2 className="text-xl font-bold">Ändern Klasse Passwort</h2>
        </div>
        <div className="card mt-6 mb-4 bg-white dark:bg-gray-700 md:p-8 p-3 rounded-[8px]">
          <div className="p-4">
            <form
              className="flex flex-col items-start w-full gap-6"
              onSubmit={formikPassword.handleSubmit}
            >
              <div className="flex flex-col w-full">
                <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
                  <p className=" py-3 text-left w-[200px] px-3 text-[12px] lg:text-[16px]">
                    Klasse auswählen
                  </p>

                  <select
                    name="className"
                    value={formikPassword.values.className}
                    onChange={(e) =>
                      formikPassword.setFieldValue("className", e.target.value)
                    }
                    className="block h-[45px] px-4 w-full text-sm text-gray-900 rounded-md bg-[#e5e7eb] dark:bg-gray-500 dark:text-white appearance-none focus:border-none"
                  >
                    <option value="" disabled>
                      Klassennamen auswählen
                    </option>
                    <option value="Low">className1</option>
                    <option value="Medium">className2</option>
                    <option value="High">className3</option>
                  </select>
                </div>
                {formikPassword.errors.className &&
                  formikPassword.touched.className && (
                    <p className="text-red-700 dark:text-gray-200 text-xs mt-1 text-left">
                      {formikPassword.errors.className}
                    </p>
                  )}
              </div>
              <div className="flex flex-col w-full">
                <div className="input-holder w-full rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center relative">
                  <p className="text-[12px] lg:text-[16px]  text-left lg:w-[200px] w-[170px] px-3">
                    Aktuelles Passwort
                  </p>
                  <input
                    type={previousPassword ? "text" : "password"}
                    className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 w-full rounded-[6px] text-[12px] lg:text-[16px]"
                    name="prevPassword"
                    value={formikPassword.values.prevPassword}
                    onChange={formikPassword.handleChange}
                  />
                  <span
                    className="cursor-pointer text-xl absolute right-2 text-gray-500 dark:text-white"
                    onClick={() => setPreviousPassword(!previousPassword)}
                  >
                    {previousPassword ? <BiShow /> : <BiHide />}
                  </span>
                </div>
                {formikPassword.errors.prevPassword &&
                  formikPassword.touched.prevPassword && (
                    <p className="text-red-700 dark:text-gray-200 text-xs mt-1 text-left">
                      {formikPassword.errors.prevPassword}
                    </p>
                  )}
              </div>

              <div className="flex flex-col w-full">
                <div className="input-holder w-full rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center relative">
                  <p className="text-[12px] lg:text-[16px] text-left lg:w-[200px] w-[170px] px-3">
                    Neues Passwort
                  </p>
                  <input
                    type={password ? "text" : "password"}
                    className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 w-full rounded-[6px] text-[12px] lg:text-[16px]"
                    name="password"
                    value={formikPassword.values.password}
                    onChange={formikPassword.handleChange}
                  />
                  <span
                    className="cursor-pointer text-xl absolute right-2 text-gray-500  dark:text-white"
                    onClick={() => setPassword(!password)}
                  >
                    {password ? <BiShow /> : <BiHide />}
                  </span>
                </div>
                {formikPassword.errors.password &&
                  formikPassword.touched.password && (
                    <p className="text-red-700 dark:text-gray-200 text-xs mt-1 text-left">
                      {formikPassword.errors.password}
                    </p>
                  )}
              </div>

              <div className="flex flex-col w-full">
                <div className="input-holder w-full rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center relative">
                  <p className="text-[12px] lg:text-[16px] text-left lg:w-[200px] w-[170px] px-3">
                    Passwort bestätigen
                  </p>
                  <input
                    type={confirmPassword ? "text" : "password"}
                    className="input outline-none border-none p-[13px] bg-gray-200 dark:bg-gray-500 w-full rounded-[6px] text-[12px] lg:text-[16px]"
                    name="confirm_password"
                    value={formikPassword.values.confirm_password}
                    onChange={formikPassword.handleChange}
                  />
                  <span
                    className="cursor-pointer text-xl absolute right-2 text-gray-500 dark:text-white"
                    onClick={() => setConfirmPassword(!confirmPassword)}
                  >
                    {confirmPassword ? <BiShow /> : <BiHide />}
                  </span>
                </div>
                {formikPassword.errors.confirm_password &&
                  formikPassword.touched.confirm_password && (
                    <p className="text-red-700 dark:text-gray-200 text-xs mt-1 text-left">
                      {formikPassword.errors.confirm_password}
                    </p>
                  )}
              </div>

              <div className="flex items-center justify-end gap-4 mt-2 w-full">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300 text-[12px] lg:text-[16px]"
                >
                  Stornieren
                </button>
                <button
                  type="submit"
                  className={`py-2 px-4 rounded-md transition duration-300 text-[12px] h-[40px] w-[120px] flex items-center justify-center lg:text-[16px] ${
                    formikPassword.isSubmitting || !formikPassword.isValid
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-indigo-600 text-white hover:bg-indigo-700"
                  }`}
                  disabled={
                    formikPassword.isSubmitting || !formikPassword.isValid
                  }
                >
                  {passwordLoading ? <ButtonLoader2 /> : "Einreichen"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ChangeClassPassword;
