import { useState } from "react";
import axios from "axios";
import axiosInstance from "../routes/axiosInstance";

function useGetClasses() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const token = sessionStorage.getItem("token");
  const GetClasses = async (isActive, dateRange) => {
    setLoading(true);

    let filters = "";
    let params = {};

    if (isActive === 1) {
      filters = "Today";
    } else if (isActive === 2) {
      filters = "Week";
    } else if (isActive === 3) {
      filters = "Month";
    } else if (dateRange?.length === 2) {
      params.start_date = dateRange[0].format("YYYY-MM-DD");
      params.end_date = dateRange[1].format("YYYY-MM-DD");
    }

    const url = new URL(`${BASE_URL}/school/classes-list`);

    if (filters) url.searchParams.append("filters", filters);
    if (params.start_date && params.end_date) {
      url.searchParams.append("start_date", params.start_date);
      url.searchParams.append("end_date", params.end_date);
    }

    await axios
      .get(url.toString(), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setTableData(response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return { GetClasses, setLoading, loading, tableData };
}
export default useGetClasses;
