import React from "react";
import { IoIosCloseCircle } from "react-icons/io";

const FileViewModal = ({ setShow, file }) => {
  return (
    <div className="w-screen h-screen bg-[#8383838c] flex items-center justify-center overflow-none z-50 fixed top-0 left-0">
      <div className="w-[90%] md:w-[60%] py-5 px-5 bg-white dark:bg-gray-800 dark:text-white relative">
        <IoIosCloseCircle
          className="absolute -right-3 -top-2 text-2xl bg-white dark:bg-gray-700 rounded-full cursor-pointer"
          onClick={() => setShow(false)}
        />
        <h2 className="text-xl font-bold py-4">File View</h2>
        <div className="w-full border border-solid border-black dark:border-white flex items-start justify-start h-[80vh]">
          {file && (
            <iframe
              src={`${file}#toolbar=0`}
              //   width="100%"
              //   height="100%"
              title="PDF File"
              //   className="border-none bg-none"
              frameborder="0"
              border="0"
              cellspacing="0"
              style={{
                borderStyle: "none",
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FileViewModal;
