import axios from "axios";
import { useContext, useState } from "react";
import useRefreshToken from "./useRefreshToken";
import GlobalContext from "../context/GlobalContext";

const useGetProfile = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { setUserInfo } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const { RefreshToken } = useRefreshToken();

  const fetchProfile = async (token) => {
    setLoading(true);
    await axios
      .get(`${BASE_URL}/users/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setUserInfo(response.data);
      })
      .catch((error) => {
        setLoading(false);
        if (
          (error?.response?.status === 401 ||
            error?.response?.status === 500) &&
          token
        ) {
          RefreshToken();
        }
      });
  };

  return { fetchProfile };
};
export default useGetProfile;
