import React, { useState, useEffect, useContext } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import GlobalContext from "../../context/GlobalContext";

const SupportBarChart = ({ title, title2, title3, title4, data }) => {
  const { dark } = useContext(GlobalContext);

  return (
    <ResponsiveContainer className="chart-container" width="100%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke={dark ? "#fff" : "#000"} />
        <XAxis dataKey={title} stroke={dark ? "#fff" : "#000"} />
        <YAxis stroke={dark ? "#fff" : "#000"} />
        <Tooltip />
        <Legend
          className="text-left"
          wrapperStyle={{ top: -40, left: 0, textAlign: "left" }}
        />
        <Bar
          dataKey={title2}
          barSize={5}
          fill="#74fa86"
          activeBar={<Rectangle fill="pink" stroke="blue" />}
        />
        <Bar
          dataKey={title3}
          barSize={5}
          fill="#2f53f5"
          activeBar={<Rectangle fill="gold" stroke="purple" />}
        />
        <Bar
          dataKey={title4}
          barSize={5}
          fill="#FF0000"
          activeBar={<Rectangle fill="red" stroke="red" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SupportBarChart;
