import { Route, Routes } from "react-router-dom";
import MainLayout from "../layouts/main.layout";
import Dashboard from "../pages/dashboard";
import AlleSchulen from "../pages/alle-schulen";
import SchuleHinzufugen from "../pages/schule-hinzufügen";
import DocumentenBibliotek from "../pages/dokumenten-bibliotek";
import Vereinbarungen from "../pages/vereinbarungen";
import EmailLog from "../pages/email-log";
import SystemLog from "../pages/system-log";
import LandingPage from "../pages/landing-page";
import Register from "../pages/auth/register";
import Login from "../pages/auth/login";
import ForgotPassword from "../pages/auth/forgotPassword";
import VerifyOtp from "../pages/auth/verifyOTP";
import NotFound from "../pages/errorhandling/404Page";
import Broadcaster from "../pages/broadcaster";
import ChangePassword from "../pages/auth/changePassword";
import TicketSystem from "../pages/ticket-system";
import Questions from "../pages/questions";
import Settings from "../pages/settings";
import ProtectedRoute from "./ProtectedRoute";
import ChangeLog from "../pages/change-log";
import SchoolStructure from "../pages/schule-struktur";
import UserActivity from "../pages/user-activity";
import ClassManagement from "../pages/class-management";
import TermsCondition from "../pages/auth/termsCondition";
import UploadDocuments from "../pages/uploadFiles";
import SchoolClasses from "../pages/schoolClassesTable";
import PaymentHistory from "../pages/payment-history";
import ChangeClassPassword from "../pages/change-class-password";
import Subscription from "../pages/payment";
import PaymentSuccess from "../pages/paymentSuccess";
import OrderTransfer from "../pages/order-tranfer";
import PaymentReminder from "../pages/payment-reminder";
import EmailRoutingSettings from "../pages/email-routing-settings";
import useGetProfile from "../hooks/useGetProfile";
import { useEffect } from "react";

const Routing = () => {
  const token = sessionStorage.getItem("token");
  const { fetchProfile } = useGetProfile();
  useEffect(() => {
    if (token) {
      fetchProfile(token);
    }
  }, [token]);

  return (
    <Routes>
      {token ? (
        <>
          <Route path="/" element={<MainLayout />}>
            <Route
              path="dashboard/heim"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/alle-schulen"
              element={
                <ProtectedRoute>
                  <AlleSchulen />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/schule-hinzufugen"
              element={
                <ProtectedRoute>
                  <SchuleHinzufugen />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/dokumenten-bibliothek"
              element={
                <ProtectedRoute>
                  <DocumentenBibliotek />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/vereinbarungen"
              element={
                <ProtectedRoute>
                  <Vereinbarungen />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/email-log"
              element={
                <ProtectedRoute>
                  <EmailLog />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/system-log"
              element={
                <ProtectedRoute>
                  <SystemLog />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/broadcaster"
              element={
                <ProtectedRoute>
                  <Broadcaster />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/ticketsystem"
              element={
                <ProtectedRoute>
                  <TicketSystem />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/dokumenten-bibliotek"
              element={
                <ProtectedRoute>
                  <UploadDocuments />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/frequently-asked-questions"
              element={
                <ProtectedRoute>
                  <Questions />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/ändern-protokoll"
              element={
                <ProtectedRoute>
                  <ChangeLog />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/bestellübertragung"
              element={
                <ProtectedRoute>
                  <OrderTransfer />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/schule-struktur"
              element={
                <ProtectedRoute>
                  <SchoolStructure />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/klassenleitung"
              element={
                <ProtectedRoute>
                  <ClassManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/klassen"
              element={
                <ProtectedRoute>
                  <SchoolClasses />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/zahlungshistorie"
              element={
                <ProtectedRoute>
                  <PaymentHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/ändern-klasse-passwort"
              element={
                <ProtectedRoute>
                  <ChangeClassPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/abonnement/:type"
              element={
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/erfolg"
              element={
                <ProtectedRoute>
                  <PaymentSuccess />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/zahlungerinnerung"
              element={
                <ProtectedRoute>
                  <PaymentReminder />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard/email-routing-einstellungen"
              element={
                <ProtectedRoute>
                  <EmailRoutingSettings />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </>
      ) : (
        <>
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms-and-conditions" element={<TermsCondition />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/verify-otp/:email" element={<VerifyOtp />}>
            <Route path="2fa" element={<VerifyOtp />} />
            <Route path="forgot-password" element={<VerifyOtp />} />
          </Route>
        </>
      )}
    </Routes>
  );
};

export default Routing;
