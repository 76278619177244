import { useContext, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import ReconnectingWebSocket from "reconnecting-websocket";
import GlobalContext from "../context/GlobalContext";

function useDashboardSocket() {
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState([]);

  const { dashboardRef } = useContext(GlobalContext);

  const ConnectWebSockets = () => {
    setLoading(true);
    dashboardRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "debug") {
        console.log("debug");
      } else {
        console.log("Received data:", data);
        setMessage(data);
        setLoading(false);
      }
      if (data.type === "error") {
        console.log("error");
        setLoading(false);
      }
    };

    dashboardRef.current.onclose = (e) => {
      console.log("close");
      setLoading(false);
    };

    dashboardRef.current.onerror = (err) => {
      console.log("onError");
      setLoading(false);
    };
  };
  return {
    ConnectWebSockets,
    message,
    setLoading,
    loading,
  };
}

export default useDashboardSocket;
