import { useContext, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import ReconnectingWebSocket from "reconnecting-websocket";
import GlobalContext from "../context/GlobalContext";

function useBroadcasting() {
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState([]);

  const { setBroadcastMessage, broadcastRef } = useContext(GlobalContext);

  const ConnectWebSockets = () => {
    broadcastRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "debug") {
        console.log("debug");
      } else {
        console.log("message");
        console.log("Received data:", data);
        // setBroadcastMessage((prevMessage) => [...prevMessage, data]);
        setBroadcastMessage(data);
      }
      if (data.type === "error") {
        console.log("error");
      }
    };

    broadcastRef.current.onclose = (e) => {
      console.log("close");
    };

    broadcastRef.current.onerror = (err) => {
      console.log("onError");
    };
  };

  const BroadcastingSubmit = (payload) => {
    broadcastRef.current.send(JSON.stringify(payload));
    setLoading(false);
    console.log("Sent message:", payload);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Message is broadcasted successfully",
      customClass: {
        confirmButton: "custom-red-button",
      },
    });
  };
  return {
    ConnectWebSockets,
    BroadcastingSubmit,
    setLoading,
    loading,
  };
}

export default useBroadcasting;
