import React from "react";

const PaymentSuccess = () => {
  return (
    <main className="w-full h-[90%]">
      <div className="flex flex-col justify-between items-start w-full h-full gap-3">
        <h2 className="text-xl font-bold dark:text-white">
          Zahlung Bestätigung{" "}
        </h2>
        <div className="flex justify-center items-center gap-5 w-full h-full ">
          <div
            className="block p-6 w-[85%] lg:w-[45%] bg-white border border-gray-200 rounded-lg shadow-sm dark:shadow-slate-200
           hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
          >
            <p className="dark:text-white text-center">
              Ihre Zahlung hat bestätigt worden
            </p>
            <svg
              class="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                class="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                class="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
            <p className="dark:text-white text-center">Success</p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PaymentSuccess;
