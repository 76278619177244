import React, { PureComponent, useContext } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import GlobalContext from "../../context/GlobalContext";

const TinyBarChart = ({ title, title2, data }) => {
  const { dark } = useContext(GlobalContext);
  return (
    <ResponsiveContainer className="chart-container" width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke={dark ? "#fff" : "#000"} />
        <XAxis dataKey="name" stroke={dark ? "#fff" : "#000"} />
        <YAxis postfix="k" stroke={dark ? "#fff" : "#000"} />
        <Tooltip />
        <Legend
          className="text-left"
          wrapperStyle={{ top: -40, left: 0, textAlign: "left" }}
        />
        <Bar
          dataKey={title}
          barSize={5}
          fill="#74fa86"
          activeBar={<Rectangle fill="pink" stroke="blue" />}
        />
        <Bar
          dataKey={title2}
          barSize={5}
          fill="#2f53f5"
          activeBar={<Rectangle fill="gold" stroke="purple" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default TinyBarChart;
