import React, { useEffect, useState } from "react";
import Onboarding from "../onBoarding";
import { ButtonLoader1, ButtonLoader2 } from "../buttonLoader/ButtonLoader";
import useGetOnBoarding from "../../hooks/useGetOnBoardnig";
import { useLocation } from "react-router-dom";

const OnBoardingInstruction = ({
  onboarding_instruction,
  onboarding_instruction_last,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { GetOnBoarding, onBoardingList, addOnboardingPage, loading } =
    useGetOnBoarding();
  const location = useLocation();
  useEffect(() => {
    setIsLoading(true);
    GetOnBoarding();
    addOnboardingPage(location.pathname.slice(1));
  }, [location.pathname]);

  useEffect(() => {
    const isOnboardingCompleted = onBoardingList.some(
      (item) => item.page_name === location.pathname.slice(1) && item.completed
    );
    setIsOnboardingComplete(isOnboardingCompleted);
  }, [onBoardingList, location.pathname]);

  if (isOnboardingComplete) {
    return null;
  }

  if (!isOnboardingComplete) {
    console.log("here ");
    return (
      <main className="overflow-hidden h-screen w-full flex items-center justify-center">
        <div className=" flex dark:hidden  ">
          <ButtonLoader1 />
        </div>
        <div className=" dark:flex hidden bg-gray-800">
          <ButtonLoader2 />
        </div>
      </main>
    );
  }

  return (
    !isOnboardingComplete && (
      <Onboarding
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        setIsOnboardingComplete={setIsOnboardingComplete}
        onboarding={onboarding_instruction}
        onboarding_last={onboarding_instruction_last}
      />
    )
  );
};

export default OnBoardingInstruction;
