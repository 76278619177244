const Auth = (state, action) => {
  switch (action.type) {
    case "SET_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "SET_DARK":
      return {
        ...state,
        dark: action.payload,
      };
    case "SET_BROADCAST_MESSAGE":
      return {
        ...state,
        broadcastMessage: [
          ...state.broadcastMessage,
          ...(Array.isArray(action.payload)
            ? action.payload
            : [action.payload]),
        ],
      };
    case "REMOVE_BROADCAST_MESSAGE": {
      const { id } = action.payload;
      return {
        ...state,
        broadcastMessage: state.broadcastMessage.filter(
          (message) => message.id !== id
        ),
      };
    }
    default:
      return state;
  }
};
export default Auth;
