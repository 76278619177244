import React from "react";

const AccentButton = ({ text, onClick }) => {
  return (
    <div className="flex items-center justify-between">
      <button
        onClick={onClick}
        className="text-white font-bold py-4 px-4 rounded outline-none w-full bg-indigo-500 dark:bg-gray-800 dark:text-white dark:shadow-gray-5  shadow-lg shadow-indigo-600 hover:bg-indigo-600 transition-all duration-300 ease-out"
      >
        {text}
      </button>
    </div>
  );
};

const TransparentButton = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="font-bold text-slate-100 hover:underline hover:text-indigo-600 "
    >
      {text}
    </button>
  );
};

export default AccentButton;
export { TransparentButton };
