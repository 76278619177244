import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { IoWarningSharp } from "react-icons/io5";
import { AiOutlineMessage } from "react-icons/ai";
import { FaBars, FaRegBell } from "react-icons/fa";
import { RxAvatar } from "react-icons/rx";
import { GoChevronDown } from "react-icons/go";
import logo from "../../assets/images/logo.png";
import NotificationList from "../notifications";
import useBroadcasting from "../../hooks/useBroadcasting";
import GlobalContext from "../../context/GlobalContext";
import ReconnectingWebSocket from "reconnecting-websocket";

const Header = ({ toggleSidebar }) => {
  const [showLogout, setShowLogout] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const notifyRef = useRef(null);
  const { ConnectWebSockets } = useBroadcasting();
  const { userInfo, broadcastRef, broadcastMessage } =
    useContext(GlobalContext);
  const firstName = userInfo?.first_name;
  const lastName = userInfo?.last_name;

  const accessToken = sessionStorage.getItem("token");
  const BASE_URL = process.env.REACT_APP_SOCKET_URL;
  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };
  const handleClickOutside = (event) => {
    if (notifyRef.current && !notifyRef.current.contains(event.target)) {
      setShowNotify(false);
      setShowLogout(false);
    }
  };
  useEffect(() => {
    if (showNotify || showLogout) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNotify, showLogout]);

  // useEffect(() => {
  //   initializeSocketConnection(webSockets);
  // }, []);
  useEffect(() => {
    broadcastRef.current = new ReconnectingWebSocket(
      `${BASE_URL}/ws/broadcast?token=${accessToken}`
    );
    ConnectWebSockets();
  }, []);
  const WarningNotification = () => {
    const warnungNotifications = broadcastMessage.flatMap((item) =>
      item.notifications.filter(
        (notification) => notification.type === "Warnung"
      )
    );
    return (
      warnungNotifications.length > 0 && (
        <div className="w-[20%] md:flex hidden justify-center items-center">
          <button className="bg-red-600 flex justify-center items-center gap-4 text-xs text-white px-4 py-1 rounded-[2px]">
            <IoWarningSharp />
            <span>{warnungNotifications[0].message}</span>
          </button>
        </div>
      )
    );
  };
  return (
    <header
      ref={notifyRef}
      className="p-[20px] shadow-md flex justify-between items-center gap-2 relative z-100 dark:bg-gray-900"
    >
      <Link to="/" className="text-xl font-bold hidden md:flex">
        <img src={logo} className="logo w-[250px] h-[20px]" alt="" />
      </Link>
      <div className="search w-[40%] ml-3 md:flex hidden items-center gap-2 dark:bg-slate-600 py-3 px-4 rounded-md">
        <CiSearch className="text-xl dark:text-white" />
        <input
          type="text"
          placeholder="suchen..."
          className="border-0 w-full h-full outline-none text-sm px-4 dark:placeholder:text-gray-400 bg-transparent dark:text-white"
          name=""
          id=""
        />
      </div>
      {/* <div className="w-[20%] md:flex hidden justify-center items-center">
        <button className="bg-red-600 flex justify-center items-center gap-4 text-xs text-white px-4 py-1 rounded-[2px]">
          <IoWarningSharp />
          <span>HINWEIS TEXT</span>
        </button>
      </div> */}
      <WarningNotification />
      <div className="md:hidden flex items-center gap-2">
        <button onClick={toggleSidebar}>
          <FaBars className="dark:text-white" />
        </button>
        <img src={logo} className="w-[130px]" alt="" />
      </div>
      <div className="actions w-[40%] flex justify-end items-center gap-4">
        <AiOutlineMessage className="text-xl text-gray-500 dark:text-gray-100" />
        <div className="notification relative">
          <FaRegBell
            className="text-xl text-gray-500 dark:text-gray-100 cursor-pointer"
            onClick={() => setShowNotify(!showNotify)}
          />
          <div className="new  absolute -top-2 -right-3 flex items-center  justify-start gap5">
            <p className="bg-red-500 w-[8px] h-[8px] rounded-full"></p>
          </div>
        </div>
        <div className="flex justify-start items-center gap-2 relative">
          <RxAvatar className="text-2xl text-gray-500 dark:text-gray-100" />
          <div className="text-gray-500 text-xs flex items-center">
            <span className="text-gray-500 text-xs md:block hidden dark:text-gray-100 capitalize">
              {firstName} {lastName}
            </span>
            <GoChevronDown
              className="text-xl dark:text-gray-100 cursor-pointer"
              onClick={() => setShowLogout(!showLogout)}
            />
          </div>
          {showLogout && (
            <div
              onClick={() => handleLogout()}
              className="bg-gray-200 cursor-pointer dark:bg-gray-600 dark:text-white py-2 px-3 border-solid border-gray-400 border-[2px] absolute top-7 -left-4 rounded-md"
            >
              Logout
            </div>
          )}
        </div>
      </div>
      {showNotify && <NotificationList />}
    </header>
  );
};

export default Header;
