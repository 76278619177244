import ReactDOM from "react-dom/client";
import "./assets/css/config.css";
import "./assets/css/global.css";
import { BrowserRouter, RouterProvider } from "react-router-dom";
// import { router } from "./routes/router";
import { GlobalProvider } from "./context/GlobalContext";
import Routing from "./routes/Routing";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GlobalProvider>
    {/* <RouterProvider router={router} /> */}
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  </GlobalProvider>
);
