import React from "react";

const logs = [
  {
    id: 1,
    recipient: "user1@example.com",
    date: "2024-10-01",
    status: "Gesendet",
  },
  {
    id: 2,
    recipient: "user2@example.com",
    date: "2024-10-02",
    status: "Fehlgeschlagen",
  },
  {
    id: 3,
    recipient: "user3@example.com",
    date: "2024-10-03",
    status: "Gesendet",
  },
];

const ReminderLogs = () => {
  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full mx-auto">
      <h2 className="text-xl font-semibold mb-4 dark:text-white">
        Erinnerungsprotokolle
      </h2>
      <div className="overflow-x-auto">
        <table className="w-full text-left  dark:border-gray-700">
          <thead>
            <tr>
              <th className="p-3 border-b dark:border-gray-700">Empfänger</th>
              <th className="p-3 border-b dark:border-gray-700">Datum</th>
              <th className="p-3 border-b dark:border-gray-700">Status</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => (
              <tr key={log.id}>
                <td className="p-3 border-b dark:border-gray-700">
                  {log.recipient}
                </td>
                <td className="p-3 border-b dark:border-gray-700">
                  {log.date}
                </td>
                <td className="p-3 border-b dark:border-gray-700">
                  {log.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReminderLogs;
