import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";
import { IoIosClose } from "react-icons/io";
import rawData from "../../components/rawData";
import OnBoardingInstruction from "../../components/onBoardingInstruction";
const { onboarding_changelog, onboarding_changelog_last } = rawData;

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
const getWeekRange = (date) => {
  const start = startOfWeek(date, { weekStartsOn: 1 });
  const end = endOfWeek(date, { weekStartsOn: 1 });
  return [start, end];
};

const getMonthRange = (date) => {
  const start = startOfMonth(date);
  const end = endOfMonth(date);
  return [start, end];
};

const OrderTransfer = () => {
  const [isActive, setIsActive] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState([]);

  const rowsPerPage = 10;

  const { RangePicker } = DatePicker;
  const tableData = [
    {
      id: 1,
      user_id: "1.0.0",
      user_name: "Besonderheit",
      amount: 200,
      status: "true",
      date: "2024-07-21",
    },
    {
      id: 2,
      user_id: "1.0.0",
      user_name: "Besonderheit",
      amount: 200,
      status: "true",
      date: "2024-07-11",
    },
    {
      id: 3,
      date: "2024-07-10",
      user_id: "1.0.0",
      user_name: "Besonderheit",
      amount: 200,
      status: "true",
    },
    {
      id: 4,
      date: "2024-07-06",
      user_id: "1.0.0",
      user_name: "Besonderheit",
      amount: 200,
      status: "true",
    },
    {
      id: 5,
      user_id: "1.0.0",
      user_name: "Besonderheit",
      amount: 200,
      status: "true",
      date: "2024-08-26",
    },
    {
      id: 6,
      user_id: "1.0.0",
      user_name: "Besonderheit",
      amount: 200,
      status: "true",
      date: "2024-08-27",
    },
    {
      id: 7,
      user_id: "1.0.0",
      user_name: "Besonderheit",
      amount: 200,
      status: "true",
      date: "2024-07-01",
    },
    {
      id: 8,
      user_id: "1.0.0",
      user_name: "Besonderheit",
      amount: 200,
      status: "true",
      date: "2024-08-21",
    },
    {
      id: 9,
      user_id: "1.0.0",
      user_name: "Besonderheit",
      amount: 200,
      status: "true",
      date: "2024-08-11",
    },
  ];
  const handleDateRangeChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setDateRange([start ? dayjs(start) : null, end ? dayjs(end) : null]);
    } else {
      setDateRange([]);
    }
  };

  const filteredQuestions = tableData.filter((item) => {
    const itemDate = dayjs(item.date);
    const today = dayjs();
    let isInDateRange = false;

    switch (isActive) {
      case 1: // Tag
        isInDateRange = itemDate.isSame(today, "day");
        break;
      case 2: // Woche
        const [weekStart, weekEnd] = getWeekRange(today.toDate());
        isInDateRange =
          itemDate.isSameOrAfter(dayjs(weekStart)) &&
          itemDate.isSameOrBefore(dayjs(weekEnd));
        break;
      case 3: // Monat
        const [monthStart, monthEnd] = getMonthRange(today.toDate());
        isInDateRange =
          itemDate.isSameOrAfter(dayjs(monthStart)) &&
          itemDate.isSameOrBefore(dayjs(monthEnd));
        break;
      default:
        isInDateRange = true;
    }

    return (
      item.user_id.toLowerCase().includes(searchTerm.toLowerCase()) &&
      isInDateRange
    );
  });

  const totalPages = Math.ceil(filteredQuestions.length / rowsPerPage);
  const currentData = filteredQuestions.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  return (
    <main>
      {/* <OnBoardingInstruction
        onboarding_instruction={onboarding_changelog}
        onboarding_instruction_last={onboarding_changelog_last}
      /> */}
      <div className="flex md:flex-row flex-col justify-between items-center gap-3">
        <h2 className="text-xl font-bold dark:text-white">
          Status der Stripe-Bestellübertragungen
        </h2>
        <div className="flex md:flex-row flex-col justify-end items-center gap-5">
          <div className="flex justify-end items-center">
            <button
              onClick={() => setIsActive(1)}
              className={`${
                isActive === 1
                  ? "bg-blue-500 text-white border-blue-500 dark:bg-gray-700"
                  : "bg-white dark:bg-gray-600"
              } hover:bg-blue-500 hover:text-white border  border-gray-300 rounded px-3 py-1 text-sm dark:text-white`}
            >
              Tag
            </button>
            <button
              onClick={() => setIsActive(2)}
              className={`${
                isActive === 2
                  ? "bg-blue-500 text-white border-blue-500 dark:bg-gray-700 "
                  : "bg-white dark:bg-gray-600"
              } hover:bg-blue-500  hover:text-white border border-gray-300 rounded px-3 py-1 text-sm dark:text-white`}
            >
              Woche
            </button>
            <button
              onClick={() => setIsActive(3)}
              className={`${
                isActive === 3
                  ? "bg-blue-500 text-white border-blue-500  dark:bg-gray-700"
                  : "bg-white dark:bg-gray-600"
              } hover:bg-blue-500  hover:text-white border border-gray-300 rounded px-3 py-1 text-sm dark:text-white`}
            >
              Monat
            </button>
            {isActive !== 0 && (
              <IoIosClose
                className="text-[18px] text-gray-800 dark:text-white ml-1 cursor-pointer"
                onClick={() => setIsActive(0)}
              />
            )}
          </div>
          <RangePicker className="w-[200px]" onChange={handleDateRangeChange} />
          <button
            type="button"
            class="focus:outline-none text-white
             bg-green-700 hover:bg-green-800
              focus:ring-4 focus:ring-green-300
               font-medium rounded-lg text-sm px-5 
               py-1.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Übertragen Bestellungen
          </button>
        </div>
      </div>
      <div className="input-holder w-full rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center mt-5 mb-8">
        <p className="text-[12px] py-3 text-left lg:w-[200px] w-[100px] px-3 dark:text-white">
          Suchen
        </p>
        <input
          type="text"
          className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-200 w-full rounded-[6px] text-[12px] lg:text-[16px]"
          placeholder="Suchen Sie nach einem bestimmten Ergebnis..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className=" bg-white dark:bg-gray-700 pb-6 mb-4 rounded-[8px]">
        <div className="overflow-x-auto">
          <table className="table w-[400%] md:w-full mb-[200px]">
            <thead>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Benutzer-ID
              </th>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Benutzername
              </th>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Betrag
              </th>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Status
              </th>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Datum
              </th>
            </thead>
            <tbody>
              {currentData.length > 0 ? (
                currentData.map((item) => (
                  <tr key={item.id}>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      {item.user_id}
                    </td>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      {item.user_name}
                    </td>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      {item.amount}
                    </td>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      {item.status}
                    </td>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      {item.date}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center text-gray-500 py-4">
                    Keine Datensätze gefunden
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="pagination mt-5 flex justify-center items-center gap-2">
          <button
            className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          {[...Array(totalPages).keys()].map((page) => (
            <button
              key={page}
              className={`btn ${
                currentPage === page + 1
                  ? "bg-blue-500 text-white"
                  : "text-gray-400"
              } px-4 py-1 rounded text-sm flex justify-center items-center gap-3`}
              onClick={() => setCurrentPage(page + 1)}
            >
              {page + 1}
            </button>
          ))}
          <button
            className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </main>
  );
};

export default OrderTransfer;
